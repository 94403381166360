import React from 'react'
import TagNoRedirect from './TagNoRedirect';

const TagOption = ({ tag_name, description, count, handleClick }) => {
  // renders an option in the tag list
  return (
    <div className="TagListItem">
      <div className="TagListTitle">
        <div name={tag_name} onClick={e => handleClick(e, tag_name)}>
          <TagNoRedirect tag_name={tag_name} />
        </div>        
        <div className="paragraphBig">
          {count}
        </div>
      </div>
      <div className="paragraphTight">
        {description}
      </div>
    </div>
  )
}

export default TagOption
