import styled from 'styled-components';

export const StyledLogIn = styled.div`
margin:auto;
display: flex;
align-items: center;
text-align: center;
justify-content:center;
color: #000000;
background: ${({ theme }) => theme.primaryLight};
border: 1px solid ${({ theme }) => theme.darkGrey};
box-sizing: border-box;
padding: ${({ theme }) => theme.mediumMargin};

h1 {
    margin-bottom: ${({ theme }) => theme.mediumMargin};
}

img{
    width:100px;
    margin-bottom: ${({ theme }) => theme.smallMargin};
}

input{
    background: #F2F2F2;
    border: 1px solid rgba(0,0,0,0.5);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 5px;
    width: 451px;
    margin: auto;
    height: 31px;
    margin: ${({ theme }) => theme.smallMargin} 0;
}

button {
    margin-top: ${({ theme }) => theme.smallMargin};
}

h4 {
    margin-bottom: ${({ theme }) => theme.mediumMargin};
}

.error{
    margin:0;
}

.bottomText {
    margin-top: ${({ theme }) => theme.smallMargin};
}

`;
