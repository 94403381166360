import React from 'react';
import { bool } from 'prop-types';
import { StyledSideMenu } from './SideMenu.styled';

const SideMenu = ({ open }) => {
  return (
    <StyledSideMenu open={open}>
      <a href="/">
        Profile
      </a>
      <a href="/">
        Tags
        </a>
      <a href="/">
        Users
      </a>
      <a href="/">
        Users
      </a>
      <a href="/">
        Chat rooms
      </a>
      <a href="/">
        Blogs
      </a>
      <a href="/">
        About
      </a>
      <a href="/">
       Contact Us
      </a>
      <a href="/">
       Help
      </a>
      <a href="/">
        Pllanet
      </a>
    </StyledSideMenu>
  )
}
SideMenu.propTypes = {
  open: bool.isRequired,
}
export default SideMenu;