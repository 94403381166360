import React, { useState } from 'react';
import { connect } from 'react-redux'
import { loadUser, logout } from '../../store/action/auth'
import firebase from 'firebase'

const DashboardEmail = ({ email, email_verified, logout, loadUser }) => {

  // component states for storing input and toggling input render
  const [inputData, setInputData] = useState({
    email,
    password: '',

    newEmail: '',
    confirmEmail: '',

    newPassword: '',
    confirmPassword: '',

  })
  // toggle email change and password change
  const [toggleEmail, setToggleEmail] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleVerify, setToggleVerify] = useState(email_verified)

  // error state
  const [error, setError] = useState();

  // store user input in component state
  const onChange = e => setInputData({ ...inputData, [e.target.name]: e.target.value });

  // TODO: optimize with async await
  const handleClickEmail = async e => {
    // NOTE: after changing there is a slight delay before the email changes
    let allowToggle = true;
    let error = {};

    if (toggleEmail) {
      // reauthenticate to see if password is correct
      let current_user = firebase.auth().currentUser;
      await current_user.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(inputData.email, inputData.password)).then(function() {
        // check if the two emails provided match
        if (inputData.newEmail !== inputData.confirmEmail) {
          let error = { message: "Emails do not match" };
          throw error;
        }
        else {
          // update email for user in firebase
          current_user.updateEmail(inputData.newEmail).then(function() {
            loadUser();
            // update new email in state
            setInputData({
              email: inputData.newEmail,
              password: '',

              newEmail: '',
              confirmEmail: '',

              newPassword: '',
              confirmPassword: ''
            })
          }).catch(function(err) {
            throw err;
          })
        }
        // set the error in the state to display on screen, don't switch back to no-input screen
      }).catch(function(err) {
        error = err;
        allowToggle = false;
      })

    }
    // reset input to not store anything
    setInputData({
      ...inputData,
      password: '',

      newEmail: '',
      confirmEmail: '',

      newPassword: '',
      confirmPassword: ''
    })
    // set error message to display on screen
    setError(error);

    // only toggle when allowToggle is true
    allowToggle && setToggleEmail(!toggleEmail);

  }
  const handleClickPassword = async e => {
    // TODO: if user is submitting change password
    let allowToggle = true;
    let error = {};

    if (togglePassword) {
      try {
        
        if (inputData.newPassword !== inputData.confirmPassword) {
          let error = { message: "Passwords do not match" }
          throw error;
        }
        else {
          let current_user = firebase.auth().currentUser;
          await current_user.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(inputData.email, inputData.password));
          await current_user.updatePassword(inputData.newPassword);
          logout();

        }

      } catch (err) {
        error = err
        allowToggle = false;
      }

    }

    // reset input to not store anything
    setInputData({
      ...inputData,
      password: '',

      newEmail: '',
      confirmEmail: '',

      newPassword: '',
      confirmPassword: ''
    })

    setError(error);
    allowToggle && setTogglePassword(!togglePassword);
  }
  const handleClickVerify = async e => {
    let current_user = firebase.auth().currentUser;
    await current_user.sendEmailVerification();
    setToggleVerify(true);
  }

  const renderInputs = () => {
    if (toggleEmail) {
      return (
        <span>
          <input type="password" name="password" placeholder="Type your current password" value={inputData.password} onChange={e => onChange(e)} />
          <input type="email" name="newEmail" placeholder="Type your new email" value={inputData.newEmail} onChange={e => onChange(e)} />
          <input type="email" name="confirmEmail" placeholder="Confirm your new email" value={inputData.confirmEmail} onChange={e => onChange(e)} />
        </span>
      )
    }
    else if (togglePassword) {
      return (
        <span>
          <input type="password" name="password" placeholder="Type your current password" value={inputData.password} onChange={e => onChange(e)} />
          <input type="password" name="newPassword" placeholder="Type your new password" value={inputData.newPassword} onChange={e => onChange(e)} />
          <input type="password" name="confirmPassword" placeholder="Confirm your new password" value={inputData.confirmPassword} onChange={e => onChange(e)} />
        </span>
      )
    }
    // if email is not verified, display a message based on if user clicked button
    else if (!email_verified) {
      return (toggleVerify ? <p>Email send. Please check your junk/spam if it doesn't appear.</p> : <p>Email Verification: Unverified</p>)
    }
  }
  const renderError = () => {
    if (error) {
      return (
        <div>{error.message}</div>
      )
    }
  }

  return (
    <tr>
      <th>Email</th>
      <th>
        {inputData.email}
        {renderInputs()}
        {renderError()}
      </th>
      <th>
        {!togglePassword && <button name="email" onClick={e => handleClickEmail(e)}>{toggleEmail ? "Submit" : "Change Email Address"}</button>}
        {!toggleEmail && <button name="password" onClick={e => handleClickPassword(e)}>{togglePassword ? "Submit" : "Change Password"}</button>}
        { // show email verified button if user is verified, otherwise display verify email button. On click of the button, it goes away
          email_verified ?
            <button>Email Verified</button> :
            // make sure it isn't clicked, and the user isn't changing email or password
            (!toggleVerify && !togglePassword && !toggleEmail) && <button name="verify" onClick={e => handleClickVerify(e)}>Verify Email Address</button>}
      </th>
    </tr>
  )
}


const mapDispatchToProps = {
  loadUser,
  logout
}

export default connect(null, mapDispatchToProps)(DashboardEmail)