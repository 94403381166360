import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { StyledsideBar } from './Sidebar.styled';

const LoginMessage = <p className="NotLoggedMessage paragraph">Please Log in to view this section</p>

const Sidebar = ({ auth: { loading, isAuthenticated, user } }) => {
  // If user is not authenticated, make the profile and chat 
  // room links disabled and on hover it should say "login to access xxx"
  return (
    <StyledsideBar>
      <nav className="navigation">
        <div className="items">
          <ul>
            <li>{isAuthenticated ? <Link to={`/users/${user ? user.uid : ""}`} className="Enabled">Profile</Link> : <span className="Disabled">Profile{LoginMessage}</span>}</li>
            <li><Link to="/tags">Tags</Link></li>
            <li><Link to="/users">Users</Link></li>
            <li>{isAuthenticated ? <Link to="/chatrooms" className="Enabled">Chat rooms</Link> : <span className="Disabled">Chat rooms{LoginMessage}</span>}</li>
            <li><a href="https://medium.com/pllanet">Blog</a></li>
          </ul>
        </div>
      </nav>
    </StyledsideBar>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
