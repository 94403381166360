import firebase from 'firebase'
var config = {
  apiKey: "AIzaSyDUiNIAiOvp9cCEtAQWeR5ALpVf39cmm1E",
  authDomain: "planet-bbef3.firebaseapp.com",
  databaseURL: "https://planet-bbef3.firebaseio.com",
  projectId: "planet-bbef3",
  storageBucket: "planet-bbef3.appspot.com",
  messagingSenderId: "855823730650"
};
var fb = firebase.initializeApp(config);
export var db = firebase.firestore()
export default fb;