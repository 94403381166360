import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { updateProfile, getProfileById, clearProfile } from '../../store/action/profile'
import { loadUser } from '../../store/action/auth'
import DashboardDisplayName from './DashboardDisplayName'
import DashboardLearningLanguage from './DashboardLearningLanguage'
import DashboardEmail from './DashboardEmail'
import DashboardPersonalInterests from './DashboardPersonalInterests'
import DashboardNativeLanguage from './DashboardNativeLanguage'
import DashboardNativeLocation from './DashboardNativeLocation'
import DashboardWatchTags from './DashboardWatchTags'
import { clearAlert } from '../../store/action/alert'
import { db } from '../../firebase'
// import { user_profile, PllanetQ, owner } from '../../values';
import { StyledProfile } from './Profile.styled';

import { user_profile, pllanetq, owner } from '../../values'
import Tag from '../common/Tag'




// the profile is loaded by now, since this is rendered in Profile.js which checks if profile is loaded
const Dashboard = ({ isUser, auth: { user }, profile: { profile, loading }, updateProfile }) => {
  // let newProfileRetrieved = false;
  useEffect(() => {
    return () => clearAlert();
  }, [])

  // click handler
  // when submitting, submit the user id from the auth token
  const handleSubmit = (data) => {
    // check which collection data should be placed into
    let location = Object.getOwnPropertyNames(data)[0] === "watch_tags"? db.collection(user_profile).doc(user.uid).collection(pllanetq).doc("private") : db.collection(user_profile).doc(user.uid);
    updateProfile(profile, data, location);
  }

  // TODO: Finish top section
  return (
    <StyledProfile>
          <table> 
        <thead className="DahBoardTop">
          <tr>
            <th>Points</th>
            <th>Questions</th>
            <th>Answers</th>
            <th>Top Tags</th>
          </tr>
          <tr>
            <th>{profile.read_only.points}</th>
            <th>{profile.read_only.question_count}</th>
            <th>{profile.read_only.answer_count}</th>
            <th>{profile.read_only.pllanetq_tags.length > 0 
            && profile.read_only.pllanetq_tags.sort(([aTag, aCount], [bTag, bCount]) => {return bCount - aCount}).slice(0,3).map(([tag_name, count]) => <Tag tag_name={tag_name} />)
            }</th>
          </tr>
        </thead>
        <tbody>
          {/* fix bug where when going to different profile then coming back to your own, the user sees the previous user's email rather than theirs */}
          <DashboardDisplayName displayed_name={profile.displayed_name} handleSubmit={handleSubmit} isUser={isUser} />
          {isUser && !loading &&
            <Fragment>
              <DashboardEmail email={user.email} verified={user.email_verified} handleSubmit={handleSubmit} />
              <DashboardWatchTags watch_tags={profile.watch_tags} handleSubmit={handleSubmit} isUser={isUser} />
            </Fragment>
          }
          <DashboardNativeLanguage native_language={profile.native_language} handleSubmit={handleSubmit} isUser={isUser} />
          <DashboardLearningLanguage learning_language={profile.learning_language} handleSubmit={handleSubmit} isUser={isUser} />
          <DashboardNativeLocation native_location={profile.native_location} handleSubmit={handleSubmit} isUser={isUser} />
          <DashboardPersonalInterests user_interest={profile.user_interest} handleSubmit={handleSubmit} isUser={isUser} />
        </tbody>

      </table> 
    </StyledProfile>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
})

const mapDispatchToProps = {
  updateProfile,
  getProfileById,
  loadUser,
  clearProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)