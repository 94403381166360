import React from 'react';
import { StyledAbout } from './Page.styled';
import Logo from "../../assets/image/logo_black.png";
import About1 from "../../assets/image/about1.PNG";
import About2 from "../../assets/image/about2.PNG";
import {Link} from 'react-router-dom'



const About = () => {
  return (
      <StyledAbout>
          <div>
            <img src={Logo}/>
            <h1>The Free Language Learning Question and Answer Platform Built for language enthusiasts to share knowledge</h1>
            <br></br>
            <h3>At PllanetQ, you can ask any question on any languages (French, English, etc. not C++), answer questions, 
                comment, upvote / downvote for free.</h3>
            <br></br>
            <h3> <Link to="/register">Sign up</Link> or <Link to="/login">Log in</Link> in order to start asking / answering All questions,
                answers and comments have to be in English</h3>
            <br></br>
            <h3>Visit <Link to="/help">Help</Link> to see how it works </h3>
            {/* <Link to="/questions/ask">Create Question</Link> */}
            <div className="Screenshots">
              <img className="aboutPic" src={About1}/>
              <img className="aboutPic" src={About2}/>
            </div>
        </div>
      </StyledAbout>
  )
}


export default About;
