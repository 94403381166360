import {
  LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, AUTH_ERROR, CLEAR_PROFILE
} from './types';
import { setAlert } from './alert'
import fb, { db } from '../../firebase';
import firebase from "firebase";
// import $ from "jquery";
import $ from 'jquery';


// Login action
export const login = (email, password) => async dispatch => {
  try {
    // try to sign in user with submitted email and password
    await fb.auth().signInWithEmailAndPassword(email, password);
    // when signed in, load user and clear alert
    dispatch(loadUser());
  } catch (err) {
    // wrong password or email, say invalid, otherwise display the message
    dispatch({
      type: LOGIN_FAIL
    })
    // don't let the user know if they got email or password wrong, just say invalid email or password
    let message = err.code === "auth/wrong-password" || "auth/user-not-found" ? "Invalid Email or Password" : err.message;
    dispatch(setAlert(message, "error"));
  }

}

export const register = (email, password, displayed_name, learning_language, native_language, hear_about_us) => async dispatch => {
  try {
    await fb.auth().createUserWithEmailAndPassword(email, password);
    // putting info into the user profile collection
    let user = fb.auth().currentUser;
    await user.sendEmailVerification();
    let idToken = await user.getIdToken(true);
    await $.post('/users/register', { token: idToken, profile: { displayed_name, learning_language, native_language } });

    // putting info in marketing collection
    let doc = await db.collection('marketing').doc("ll_hear_about_us").get();

    if (doc.data().hasOwnProperty(hear_about_us)) {
      // add a plus one counter to the corresponding hear about us field
      db.collection("marketing").doc("ll_hear_about_us").update({
        [hear_about_us]: firebase.firestore.FieldValue.increment(1)
      })
    } else {
      // add the user's response to the "other" field
      hear_about_us = hear_about_us ? hear_about_us.toLowerCase() : "null";
      db.collection("marketing").doc("ll_hear_about_us").update({
        [`other.${hear_about_us}`]: firebase.firestore.FieldValue.increment(1)
      })
    }

    dispatch(loadUser());
    // TODO: display error with state? Same issue with login and other errors in actions
  } catch (error) {
    dispatch(setAlert(error.message, "error"));
  }

}


// Logout action
export const logout = () => dispatch => {
  firebase.auth().signOut().then(function() {
    dispatch({
      type: LOGOUT
    })
    dispatch({
      type: CLEAR_PROFILE
    })
  })
    .catch(function(error) {
      dispatch({
        type: AUTH_ERROR
      })
      dispatch(setAlert(error.message, "error"));
    })
}

// loadUser action - check if a user is logged in
export const loadUser = () => dispatch => {
  // authFlag variable to make onAuthStateChanged run only once
  let authFlag = true;
  fb.auth().onAuthStateChanged(async function(user) {
    try {
      if (authFlag && user) {
        // verify user through backend by getting firebase token
        let idToken = await fb.auth().currentUser.getIdToken(true);
        let user = await $.getJSON('/users/firebase_token', { token: idToken });
        // user is logged in, store user info inside store
        dispatch({
          type: LOGIN_SUCCESS,
          payload: user
        })
      }
      else {
        // no user found, cannot authenticate, dispatch AUTH_ERROR
        let error = {}
        throw error;
      }
      // no issues, clear any alert and user is logged in. set authFlag to false so the listener doesn't run again
      authFlag = false;

    } catch (err) {
      dispatch({
        type: AUTH_ERROR
      })
      if (err.message) {
        dispatch(setAlert(err.message, "error"));
      }
    }
  })
}
