import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom';
import { getQuestionsAlgolia, sortQuestions, clearQuestions } from '../../store/action/question';
import { getProfileById, clearProfile } from '../../store/action/profile';
import DisplayQuestionsByPagesAlgolia from './DisplayQuestionsByPagesAlgolia';
import SearchBar from '../common/SearchBar';
import Tag from '../common/Tag';
import TagsList from '../common/TagsList';
import { StyledHome } from './Page.styled';

const Home = ({ auth, question: { questions, algolia, loading }, profile, getQuestionsAlgolia, sortQuestions, clearQuestions, getProfileById, clearProfile, location }) => {
  const [inputData, setInputData] = useState({
    search: "",
    sortBy: "relevance",
    searchSetting: [],
    tags: [],
    pageNumber: 0
  })
  const [customFilterOn, setCustomFilterOn] = useState(false)
  const [watchTagsLoaded, setWatchTagsLoaded] = useState(false)
  const [tagQuery, setTagQuery] = useState(null)

  // initial useEffect. Check if user is logged in, and check if there is a tag query
  useEffect(() => {
    // see if there is a tag query, if there is load that and skip loading watch tags
    const params = new URLSearchParams(location.search);
    const tag = params.get('tag');
    setTagQuery(tag)
    // if there are tags, then setInputData to the tag
    tag && setInputData({
      ...inputData,
      tags: [tag]
    })
    // load watch tags if there isn't a tag query
    // if user is logged in, get their profile
    !tag && auth.isAuthenticated && getProfileById(auth.user.uid)
    return () => {
      clearProfile()
    }
  }, [auth.isAuthenticated, getProfileById])

  // this will only run if a profile exists
  // we decide if we need to get profile from above useEffect
  useEffect(() => {
    // if the user's profile exists, load watch tags into filter
    if (profile.profile) {
      setInputData({
        ...inputData,
        tags: profile.profile.watch_tags
      })
      setWatchTagsLoaded(true);
    }
  }, [profile.profile])

  // if the conditions are met, then load the questions and when they are met the correct filter will be in the state
  useEffect(() => {
    // if it finished loading the user, then get questions
    if (tagQuery) {
      getQuestionsAlgolia(inputData.pageNumber, inputData.search, { filters: filterString() });
    }
    // if we have finished loading to check if the user is logged in
    else if (!auth.loading) {
      // user isn't signed in, no need to wait for watch tags to load
      if (!auth.isAuthenticated) {
        getQuestionsAlgolia(inputData.pageNumber, inputData.search, { filters: filterString() });
      }
      // user is signed in, wait until it is done loading watch tags as filter
      else if (auth.isAuthenticated && watchTagsLoaded) {
        getQuestionsAlgolia(inputData.pageNumber, inputData.search, { filters: filterString() });
      }
    }
    // sortQuestions(questions, inputData.sortBy);
    return () => {
      clearQuestions()
    }

    // When the pageNumber changes we will get the next set of algolia questions
  }, [tagQuery, auth, watchTagsLoaded, inputData.pageNumber])

  // sort whenever user selects a sort option or new questions are stored in the state
  useEffect(() => {
    // make sure questions are loaded before sorting
    questions && questions.length > 0 && sortQuestions(questions, inputData.sortBy);
    // whenever questions changes sort the question based on filter
  }, [questions])


  const onChange = e => setInputData({ ...inputData, [e.target.name]: e.target.value });
  const addFilter = e => {
    // if it's in the array, remove it
    if (inputData.searchSetting.includes(e.target.value)) {
      // remove from inputData, then return the rest of inputData
      let index = inputData.searchSetting.indexOf(e.target.value)
      inputData.searchSetting.splice(index, 1)
      setInputData({ ...inputData, searchSetting: [...inputData.searchSetting] });
    }
    else {
      // if it doesn't already exist in array and there is available space, then add it to the array
      setInputData({ ...inputData, searchSetting: [...inputData.searchSetting, e.target.value] })
    }
  }
  // on select, store into inputData
  const onSelectTag = (tag) => {

    // if it's in the array, remove it
    if (inputData.tags.includes(tag)) {
      // remove from inputData, then return the rest of inputData
      let index = inputData.tags.indexOf(tag)
      inputData.tags.splice(index, 1)
      setInputData({ ...inputData, tags: [...inputData.tags] });

    }
    else {
      // if it doesn't already exist in array and there is available space, then add it to the array
      setInputData({ ...inputData, tags: [...inputData.tags, tag] })
    }
  }
  const onSubmit = e => {
    getQuestionsAlgolia(inputData.pageNumber, inputData.search, { filters: filterString() });
  }
  const setPageNumber = (num) => setInputData({ ...inputData, pageNumber: num })
  // convert search parameters to algolia format for filter
  const filterString = () => {
    const answerFilter = inputData.searchSetting.join(" OR ");
    const tagFilter = inputData.tags.join(" AND ")
    const filter = (answerFilter + (answerFilter && tagFilter && " AND ") + tagFilter)
    return (filter)
  }

  return (loading && questions !== null ? <div>loading...</div> :
    <StyledHome>
      <div className="fullWidth">
        <div className="titleBig mainTitle">The Free Language Learning Question and Answer Platform</div>
        <div className="HeadSection">
          <div className="searchSection">
            <SearchBar name="search" value={inputData.search} placeholder={"Search"} onSubmit={onSubmit} onChange={onChange} />
          </div>
          <div className="homeButtons">
            <button className="Button3 paragraphBig" onClick={e => setCustomFilterOn(!customFilterOn)}>Custom Filter</button>
            <Link className="Button1 paragraphBig" to="/questions/ask" >Ask Question</Link>
          </div>
        </div>
        {customFilterOn &&
          <div className="customFilter">
            <div className="filters">
              <div className="filter_row">
                <div>
                  <input type="radio" name="sortBy" value="relevance" onChange={e => onChange(e)} defaultChecked />
                  <label htmlFor="relevance">Relevance</label>
                </div>
                <div>
                  <input type="radio" name="sortBy" value="newest" onChange={e => onChange(e)} />
                  <label htmlFor="newest">Newest</label>
                </div>
                <div>
                  <input type="radio" name="sortBy" value="mostUpvotes" onChange={e => onChange(e)} />
                  <label htmlFor="mostUpvotes">Most Upvotes</label>
                </div>
              </div>
              <div className="filter_row">
                <div>
                  <input type="checkbox" name="withAnswers" value="answer_count>0" onChange={e => addFilter(e)} />
                  <label htmlFor="true">With Answers</label>
                </div>
                <div>
                  <input type="checkbox" name="withAnswers" value="answer_count=0" onChange={e => addFilter(e)} />
                  <label htmlFor="false">No Answers</label>
                </div>
              </div>
            </div>
            <div className="customs">
              <h4 className="paragraphBig custom_title">Add Tags</h4>
              <div className="SelectedTags">
                {/* display watch tags component which allows users to press x to delete, when clicked goes to tag page */}
                {inputData.tags.length > 0 && inputData.tags.map((tag) => <Tag tag_name={tag} onSelect={onSelectTag} key={tag} />)}
              </div>
              {/* display the tag search component when the change button is pressed */}
              {<TagsList onSelect={onSelectTag} currentTags={inputData.tags} />}
              {/* {tagMessage && <div>{tagMessage}</div>} */}
              <button className="Button4 ApplyFilter" onClick={e => onSubmit(e)}>Apply</button>
            </div>
          </div>
        }
        <DisplayQuestionsByPagesAlgolia questions={questions} algolia={algolia} setPageNumber={setPageNumber} />
      </div>
    </StyledHome>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  question: state.question,
  profile: state.profile
})

const mapDispatchToProps = {
  getQuestionsAlgolia,
  sortQuestions,
  clearQuestions,
  getProfileById,
  clearProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
