import React, { useState } from 'react'

const DashboardDisplayName = ({ displayed_name, handleSubmit, isUser }) => {
  // component states for storing input and toggling input render
  const [inputData, setInputData] = useState(displayed_name ? { displayed_name } : { displayed_name: '' })
  const [toggleChange, setToggleChange] = useState(false)

  // store user input in component state
  const onChange = e => setInputData({ ...inputData, [e.target.name]: e.target.value });

  // when submitting, call function to change it in Dashboard.js
  const handleClick = e => {
    if (toggleChange) {
      handleSubmit(inputData);
    }
    setToggleChange(!toggleChange);
  }
  // allow edit only if user is logged in

  return (isUser ?
    <tr>
      <th>Display Name</th>
      <th>
        {toggleChange ?
          <input type="text" name="displayed_name" value={inputData.displayed_name} onChange={e => onChange(e)} />
          : inputData.displayed_name}
      </th>
      <th>
        <button name="displayed_name" onClick={e => handleClick(e)}>{toggleChange ? "Submit" : "Change"}</button>
      </th>
    </tr> :
    <tr>
      <th>Display Name</th>
      <th>{displayed_name}</th>
    </tr>
  )
}

export default DashboardDisplayName
