export const SET_ALERT = "SET_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";

export const GET_PROFILE = "GET_PROFILE";
export const GET_USERS = "GET_USERS";
export const SORT_USERS = "SORT_USERS";
export const CLEAR_USERS = "CLEAR_USERS";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const GET_QUESTION_BY_ID = "GET_QUESTION_BY_ID"
export const GET_QUESTIONS = "GET_QUESTIONS"
export const GET_QUESTIONS_ALGOLIA = "GET_QUESTIONS_ALGOLIA"
export const CLEAR_CURRENT_QUESTION = "CLEAR_CURRENT_QUESTION"
export const CLEAR_QUESTIONS = "CLEAR_QUESTIONS"