import React, { useState } from 'react';
import { languages } from '../../values';

const DashboardeNativeLanguage = ({ native_language, handleSubmit, isUser }) => {
  // component states for storing input and toggling input render
  const [inputData, setInputData] = useState(native_language ? { native_language } : { native_language: '' })
  const [toggleChange, setToggleChange] = useState(false)

  // store user input in component state
  const onChange = e => setInputData({ ...inputData, [e.target.name]: e.target.value });

  // when submitting, call function to change it in Dashboard.js
  const handleClick = e => {
    if (toggleChange) {
      handleSubmit(inputData);
    }
    setToggleChange(!toggleChange);
  }
  // if this field doesn't exist, let the user add it
  if (!native_language && isUser) {
    return (
      <tr>
        <th>Native Language</th>
        <th>
          {toggleChange ?
            // mapping through language list to create dropdown
            <select name="native_language" value={inputData.native_language} onChange={e => onChange(e)}>
              <option value="" disabled>Choose a location</option>
              {languages.map((lang, index) => <option value={lang} key={index}>{lang}</option>)}
            </select>
            : inputData.native_language}
        </th>
        <th>
          <button name="native_language" onClick={e => handleClick(e)}>{toggleChange ? "Submit" : "Add"}</button>
        </th>
      </tr>
    )
  }
  // if it does exist, the user can't edit it anymore
  return (
    <tr>
      <th>Native Language</th>
      <th>{native_language}</th>
    </tr>
  )
}

export default DashboardeNativeLanguage
