import React, { useState } from 'react'
import TagsList from '../common/TagsList'
import Tag from '../common/Tag'

const QuestionForm = ({ question, setQuestion, setEditing, user_id }) => {
  // inputData is a buffer state that stores input here. When you press submit you send the entire inputData back into question
  const [inputData, setInputData] = useState(question)
  const [tagMessage, setTagMessage] = useState("")

  const onChange = e => setInputData({ ...inputData, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    // TODO: check if requirements are met
    setQuestion({ ...inputData, timestamp: Date.now(), author: user_id, upvote_count: 0, bookmarks_count: 0, answer_count: 0 });
    setEditing(false);
  }
  // on select, store into inputData
  const onSelect = (tag) => {

    // if it's in the array, remove it
    if (inputData.tags.includes(tag)) {
      // remove from inputData, then return the rest of inputData
      let index = inputData.tags.indexOf(tag)
      inputData.tags.splice(index, 1)
      setInputData({ ...inputData, tags: [...inputData.tags] });
      setTagMessage("");
    }
    else {
      // check if there is more than 5 items
      if (inputData.tags.length >= 5) {
        setTagMessage("Choose up to 5 tags");
      }
      else {
        // if it doesn't already exist in array and there is available space, then add it to the array
        setInputData({ ...inputData, tags: [...inputData.tags, tag] })
        setTagMessage("");
      }
    }
  }
  return (
    <div style={{ margin: "100px 200px" }}>
      <h1>Ask a Question</h1>

      <div style={{ border: "1px solid grey" }}>
        <div>
          <h2>Title</h2>
          <h4>Between 20 to 150 characters</h4>
          <h4>Please be specific and concise.</h4>
          {/* value is so when user goes back, they can see the question info in the input */}
          <input name="title" type="text" placeholder={"e.g. \"at the airport\" or \"in the airport?\""} value={inputData.title} onChange={e => onChange(e)} />
        </div>

        <div>
          <h2>Body</h2>
          <h4>Between 20 to 30000 characters</h4>
          {/* TODO: add better text editor */}
          <textarea style={{width: "100%", height: "200px", resize: "none"}} name="content" placeholder="Please be specific and concise." value={inputData.content} onChange={e => onChange(e)}></textarea>
        </div>
        <div>
          <h2>Tags</h2>
          <h4>Add 1 to 5 tags to categorize your question, has to enter at least 1 language tag, 2 tags for translate</h4>
          {/* display watch tags component which allows users to press x to delete, when clicked goes to tag page */}
          {inputData.tags.length > 0 && inputData.tags.map((tag) => <Tag tag_name={tag} onSelect={onSelect} key={tag} />)}
          {/* display the tag search component when the change button is pressed */}
          {<TagsList onSelect={onSelect} currentTags={inputData.tags} />}
          {tagMessage && <div>{tagMessage}</div>}
        </div>
      </div>
      <div>
        <button onClick={e => onSubmit(e)}>Review Your Question</button>
      </div>


    </div>
  )
}

export default QuestionForm
