import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT, 
  AUTH_ERROR
} from "../action/types"

const initialState = {
  isAuthenticated: null,
  loading: true,
  user: null
};

export default (state = initialState, { type, payload }) => {

  switch (type) {
    // User is logged in, set the user's information in the store
    case LOGIN_SUCCESS:
      return{
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      }

    // User is not logged in, clear the user's information from the store
    case LOGOUT:
    case LOGIN_FAIL:
    case AUTH_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        user: null
      }

    default:
      return state;
  }
}