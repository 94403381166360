import React from 'react'
import { Link } from 'react-router-dom'
import Tag from '../common/Tag'

const UsersListItem = ({user:{user_id, displayed_name, points, question_count, answer_count, pllanetq_tags}}) => {
 
  return (
  <Link to={`/users/${user_id}`} style={{ textDecoration: 'none', color: "black" }}>
     <div style={{border: "1px solid grey ", height: "800px"}}>
       <div>{displayed_name}</div>
       <div>{points}</div>
       <div>{question_count}</div>
       <div>{answer_count}</div>
       { pllanetq_tags &&
         pllanetq_tags.map(([tag_name, count]) => <Tag tag_name={tag_name} />)
       }
    </div>
    </Link>
   
  )
}

export default UsersListItem
