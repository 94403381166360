import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import QuestionListItem from '../common/QuestionListItem';
import { QuestionPage } from '../common/common.styled';

const DisplayQuestionsByPagesAlgolia = ({ questions, algolia, setPageNumber }) => {
  const handlePageClick = e => {
    const selectedPage = e.selected;
    // if this is passed through, return the page number to parent component for search
    setPageNumber(selectedPage)
  }

  return (questions && algolia &&
    <div>
      <QuestionPage>
        <div>
          {questions.map((question) => <QuestionListItem question={question} />)}
        </div>    

        {/* NOTE: styling for pagination is in App.css */}
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={null}
          breakClassName={'break-me'}
          pageCount={algolia.nbPages}
          forcePage={algolia.page}
          marginPagesDisplayed={0}
          pageRangeDisplayed={5}
          onPageChange={e => handlePageClick(e)}

          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      </QuestionPage>
    </div>
  )
}

export default DisplayQuestionsByPagesAlgolia
