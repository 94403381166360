import React, { useState, useEffect } from 'react'
import Tag from '../common/Tag'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { db } from '../../firebase'
import { user_profile } from '../../values'
import { QuestionStyle } from "./QuestionPage.styled"

const Question = ({ question }) => {
  const { title, content, timestamp, author, tags } = question
  const [displayed_name, setDisplayed_name] = useState("")

  // get the author's name
  useEffect(() => {
    const getAuthorName = async () => {
      const doc = await db.collection(user_profile).doc(author).get();
      if (doc.exists && doc.data().displayed_name) {
        setDisplayed_name(doc.data().displayed_name);
      }
      else {
        setDisplayed_name("User Deleted");
      }
    }
    getAuthorName()
  }, [question])

  // display question content
  return (displayed_name !== "" &&
    <QuestionStyle>
      <div className="questionDiv">
        <h1 className="titleBig">{title}</h1>
        <div className="questionInfo">
          <div className="questionTags">
            {tags && tags.length > 0 && tags.map((tag) => <Tag tag_name={tag} key={tag} />)}
          </div>
          <p>{moment(timestamp).fromNow()} by <Link to={`/users/${author}`}>{displayed_name}</Link></p>
        </div>
        <p className="questionContent paragraph">{content}</p>
      </div>
    </QuestionStyle>
  )
}


export default Question
