import React from 'react'
import Tag from '../common/Tag'

const ProfileTags = ({ pllanetq_tags }) => {
  return (
    <div>
      <div>
        <h1>Tags</h1>
        <h2>{pllanetq_tags.length}</h2>
      </div>
      <div>
        { pllanetq_tags.length > 0 ?
        pllanetq_tags.map(([tag_name, count]) =>
          <div>
            <div>{count}</div>
            <Tag tag_name={tag_name} />
          </div>
        )
      :
      <div>Ask or answer questions to increase your tag count!</div>
      }
      </div>
    </div>
  )
}

export default ProfileTags
