import {
  GET_PROFILE, GET_USERS, CLEAR_PROFILE, UPDATE_PROFILE, PROFILE_ERROR, CLEAR_USERS, SORT_USERS
} from './types';
import fb from '../../firebase';
import $ from "jquery";
import { setAlert } from './alert';


// set what collection the profile is from
export const getProfileById = (user_id) => async dispatch => {
  // see if user is signed in, and if they are accessing their own profile. If they are, retrieve watch tags and bookmarks, else only retrieve public info
  let getToken = new Promise(async (resolve, reject) => {
    let idToken = null;
    fb.auth().onAuthStateChanged(async function(user) {
      if (user) idToken = await fb.auth().currentUser.getIdToken(true);
      resolve(idToken);
    })
  })

  try {
    // seeing if user is signed in
    let token = await getToken;
    // retrieve information from the collection
    let profile = await $.get('/users/profile', { user_id: user_id, token });
    if (profile === "Server error") {
      let error = { message: "Cannot retrieve profile" };
      throw error
    }
    dispatch({
      type: GET_PROFILE,
      payload: profile
    })

  } catch (err) {
    dispatch({ type: PROFILE_ERROR });
    dispatch(setAlert(err.message, "error"));

  }
}

// get list of users by query, by default get all users
export const getUsersByQuery = (offset, limit, query = null) => async dispatch => {
  // query from the backend
  let payload = await $.get('/users/search', { offset, limit, query });
  // check if payload is an error, if it is make it null so no results will show up
  payload = payload === "Server error" ? null : payload;
  dispatch({
    type: GET_USERS,
    payload
  })


}
export const sortUsers = (sortBy, desc, users) => dispatch => {
  let payload = users;
  // sort then return to store
  switch (sortBy) {
    // sort by parameter, by default sort by name
    case "points":
    case "question_count":
    case "answer_count":
      payload = desc ? users.sort((a, b) => { return b[sortBy] - a[sortBy] }) : users.sort((a, b) => { return a[sortBy] - b[sortBy] })
      break;
    default:
      payload = desc ? users.sort((a, b) => a.displayed_name.localeCompare(b.displayed_name)) : users.sort((a, b) => b.displayed_name.localeCompare(a.displayed_name))
      break;
  }
  dispatch({
    type: SORT_USERS,
    payload
  })
}

export const updateProfile = (profile, inputData, location) => async dispatch => {
  try {
    // updates new input data into user document
    await location.set({
      ...inputData
    }, { merge: true })

    dispatch({
      type: UPDATE_PROFILE,
      payload: { ...profile, ...inputData }
    })
  } catch (err) {
    dispatch({ type: PROFILE_ERROR });
    dispatch(setAlert(err.message, "error"));
  }



}

export const clearProfile = () => dispatch => {
  dispatch({ type: CLEAR_PROFILE });
}
export const clearUsers = () => dispatch => {
  dispatch({ type: CLEAR_USERS });
}