import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Question from '../QuestionsPage/Question'
import { createQuestion, getQuestionsAlgolia } from '../../store/action/question'
import QuestionListItem from '../common/QuestionListItem'
import { Redirect } from 'react-router-dom'
import { clearAlert } from '../../store/action/alert'

const ReviewQuestion = ({ setEditing, createdQuestion, question: { currentQuestion, questions, loading }, getQuestionsAlgolia, createQuestion, history }) => {
  const [bottom, setBottom] = useState(false)

  // get similar questions
  // on load run this
  useEffect(() => {
    getQuestionsAlgolia(0, createdQuestion.title, [], true)
    return () => clearAlert();
  }, [getQuestionsAlgolia, createdQuestion])


  const handleScroll = (e) => {
    // check if scrilled to bottom
    setBottom(e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight)
  }
  // on submit dispatch create question
  const submitQuestion = e => {
    createQuestion(createdQuestion)

  }
  // when question is created and it's loaded in the store, go to the question page
  const goToQuestion = () => {
    // push browser history to this url so it redirects user correctly to question
    history.push(`/questions/${currentQuestion.question_id}`);
    // render nothing so it doesn't crash
    return null;
  }
  
  return (currentQuestion ? goToQuestion() :
    <div style={{ margin: "100px 201px" }}>
      Review Your Question
      <Question question={createdQuestion} />
      {/* relevant questions, scroll down to press publish */}
      Similar Questions
      <div style={{ overflow: "scroll", height: "200px" }} onScroll={e => handleScroll(e)}>
        {!loading && questions.map((relatedQuestion) => <QuestionListItem question={relatedQuestion} />)}
      </div>
      <button onClick={e => setEditing(true)}>Go Back to Editing</button>
      <button onClick={e => submitQuestion(e)} disabled={!bottom}>Publish Your Question</button>
    </div>
  )
}

const mapStateToProps = (state) => ({
  question: state.question
})

const mapDispatchToProps = {
  getQuestionsAlgolia,
  createQuestion
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewQuestion)
