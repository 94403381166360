// root reducer
import {combineReducers} from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import question from './question';

export default combineReducers({
  alert,
  auth,
  profile,
  question
})