import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getQuestionById, clearCurrentQuestion, answerQuestion, commentQuestion, upvoteQuestion, downvoteQuestion, bookmarkQuestion } from '../../store/action/question'
import { clearAlert } from '../../store/action/alert'
import Question from './Question'
import Answer from './Answer'
import Comment from './Comment'
import { db } from "../../firebase"
import { pllanetq_questions, upvotes, downvotes, bookmarks } from "../../values"
import { QuestionPageStyle } from "./QuestionPage.styled"

const QuestionsPage = ({ match, auth: { isAuthenticated, user }, question: { loading, currentQuestion }, getQuestionById, clearCurrentQuestion, answerQuestion, commentQuestion, upvoteQuestion, downvoteQuestion, bookmarkQuestion, clearAlert }) => {
  const [inputData, setInputData] = useState({
    answer: "",
    comment: ""
  })
  const [answerButtonClicked, setAnswerButtonClicked] = useState(false)
  const [commentButtonClicked, setCommentButtonClicked] = useState(false)
  const [hasUserUpvoted, setHasUserUpvoted] = useState(false)
  const [hasUserDownvoted, setHasUserDownvoted] = useState(false)
  const [hasUserBookmarked, setHasUserBookmarked] = useState(false)
  const [upvoteCount, setUpvoteCount] = useState(0)
  const [bookmarksCount, setBookmarksCount] = useState(0)
  const [flag, setFlag] = useState(true)
  // load the question of question_id in url
  useEffect(() => {
    getQuestionById(match.params.question_id);
    // cleanup
    return () => {
      clearCurrentQuestion();
      clearAlert();
    }
  }, [getQuestionById, match.params.question, clearCurrentQuestion, clearAlert])
  // check if user has upvoted, downvoted or bookmarked
  useEffect(() => {
    const checkVoteBookmarks = async () => {
      // if user's upvote is in the upvote collection, set the upvote state to true
      let search = await db.collection(pllanetq_questions).doc(currentQuestion.question_id).collection(upvotes).where('user_id', '==', user.uid).get();
      let upvote = search.docs.length > 0;
      setHasUserUpvoted(upvote)

      // if the user didn't upvote, check if the user downvoted
      if (!upvote) {
        // if user's upvote is in the upvote collection, set the upvote state to true
        let search = await db.collection(pllanetq_questions).doc(currentQuestion.question_id).collection(downvotes).where('user_id', '==', user.uid).get();
        setHasUserDownvoted(search.docs.length > 0)
      }

      search = await db.collection(pllanetq_questions).doc(currentQuestion.question_id).collection(bookmarks).where('user_id', '==', user.uid).get();
      setHasUserBookmarked(search.docs.length > 0)
      // first time setup
      // get initial value of count, then calculate new values locally since there is a delay if we read from fb
      if (flag) {
        setUpvoteCount(currentQuestion.upvote_count);
        setBookmarksCount(currentQuestion.bookmarks_count);
        setFlag(false)
      }


    }
    currentQuestion && user && checkVoteBookmarks();


  }, [currentQuestion, user])


  const onChange = e => setInputData({ ...inputData, [e.target.name]: e.target.value });

  // submitting info to firebase
  const submitAnswer = e => {
    answerQuestion(inputData.answer, currentQuestion.question_id)
    // reset values
    setAnswerButtonClicked(false);
    setInputData({
      answer: "",
      comment: ""
    })
  }
  const submitComment = e => {
    commentQuestion(inputData.comment, currentQuestion.question_id)
    // reset values
    setCommentButtonClicked(false);
    setInputData({
      answer: "",
      comment: ""
    })
  }
  // event handlers
  // if user has already downvoted, delete the downvote then upvote
  const submitUpvote = e => {
    if (hasUserDownvoted) {
      submitDownvote(e)
      // double for deleting upvote then downvoting
      setUpvoteCount(hasUserUpvoted ? upvoteCount - 1 : upvoteCount + 2)
    }
    else {
      setUpvoteCount(hasUserUpvoted ? upvoteCount - 1 : upvoteCount + 1)
    }
    setHasUserUpvoted(!hasUserUpvoted)
    upvoteQuestion(currentQuestion.question_id, hasUserUpvoted)
  }
  // if user has already upvoted, delete upvote then downvote
  const submitDownvote = e => {
    if (hasUserUpvoted) {
      submitUpvote(e)
      // double for deleting downvote then upvoting
      setUpvoteCount(hasUserDownvoted ? upvoteCount + 1 : upvoteCount - 2)
    }
    else setUpvoteCount(hasUserDownvoted ? upvoteCount + 1 : upvoteCount - 1)
    setHasUserDownvoted(!hasUserDownvoted)
    downvoteQuestion(currentQuestion.question_id, hasUserDownvoted)
  }
  // bookmark or unbookmark the question for user
  const submitBookmark = e => {
    bookmarkQuestion(currentQuestion.question_id, hasUserBookmarked)
    setBookmarksCount(hasUserBookmarked ? bookmarksCount - 1 : bookmarksCount + 1)
    setHasUserBookmarked(!hasUserBookmarked)
  }




  return (loading || currentQuestion === null ? <div>loading...</div> :
    <QuestionPageStyle>
      <div className="QuestionPage">
        <div className="QuestionSection">
          <div className="VotingSection">
            <div>
              <div className={hasUserUpvoted ? "Upvoted" : "Unupvoted"} onClick={e => submitUpvote(e)} >&#x25B2;</div>
              <div className="title">{upvoteCount}</div>
              <div className={hasUserDownvoted ? "Upvoted" : "Unupvoted"} onClick={e => submitDownvote(e)} >&#x25BC;</div>
            </div>
            <div>
              <div className={hasUserBookmarked ? "Upvoted" : "Unupvoted"} onClick={e => submitBookmark(e)} >&#9733;</div>
              <div className="title">{bookmarksCount}</div>
            </div>
          </div>
          <div>
            <Question question={currentQuestion} />
            <div className="QuestionButtons">
              {!answerButtonClicked && <button className="Button4 paragraphBig" title={!isAuthenticated ? "Log in or sign up to post an answer" : undefined} onClick={e => setAnswerButtonClicked(true)} disabled={!isAuthenticated}>Answer</button>}
              {!commentButtonClicked && <button className="Button3 paragraphBig" title={!isAuthenticated ? "Log in or sign up to post a comment" : undefined} onClick={e => setCommentButtonClicked(true)} disabled={!isAuthenticated}>Comment</button>}
              <a className="reportButton paragraph" disabled={!isAuthenticated}>Report</a>
            </div>

            {commentButtonClicked &&
              <div>
                <textarea className="commentSection paragraph" name="comment" placeholder="Use comments to ask for more information or suggest improvements. Avoid answering questions. Please be nice." value={inputData.comment} onChange={e => onChange(e)}></textarea>
                <button className="Button4 paragraphBig postStuff" onClick={e => submitComment(e)}>Post Comment</button>
              </div>
            }
            {answerButtonClicked &&
              <div>
                {/* TODO: add better text editor */}
                <textarea className="addComment paragraph" name="answer" placeholder="Please address the question directly. Be specific and concise." value={inputData.answer} onChange={e => onChange(e)}></textarea>
                <button className="Button4 paragraphBig postStuff" onClick={e => submitAnswer(e)}>Post Answer</button>
              </div>
            }
            <div className="commentSection">
              <div className="title commentTitle">Comment</div>
              {currentQuestion.comments.length > 0 ? currentQuestion.comments.map((comment) => <Comment comment={comment} question_id={currentQuestion.question_id} key={comment.comment_id} />) : <div className="paragraphBig">No comments yet. Why not be the first to comment?</div>}
            </div>
          </div>
        </div>

        <div>
          <div className="titleBig answerCount">{currentQuestion.answer_count} Answers</div>
          {currentQuestion.answers.length > 0 ? currentQuestion.answers.map((answer) => <Answer answer={answer} key={answer.answer_id} />) : <div>No answers yet. Why not be the first to answer?</div>}
        </div>
      </div>
    </QuestionPageStyle>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  question: state.question
})

const mapDispatchToProps = {
  getQuestionById,
  clearCurrentQuestion,
  answerQuestion,
  commentQuestion,
  upvoteQuestion,
  downvoteQuestion,
  bookmarkQuestion,
  clearAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsPage)
