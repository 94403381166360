import React, { Fragment, useEffect, useState, useRef } from 'react';
import firebase, { db } from '../../firebase';
import { pllanetq_tags } from '../../values';
import TagsListOption from './TagsListOption';
import { TagListStyle } from './common.styled';

const Tags = ({ onSelect, currentTags }) => {
  const tagListWrapper = useRef(null);
  const [inputData, setInputData] = useState("")
  const [searchedTags, setSearchedTags] = useState([])
  const [tags, setTags] = useState([])
  const [loadingTags, setLoadingTags] = useState(true)
  const [inputSelected, setInputSelected] = useState(false)

  // when the component is called, set all tags into the tags state, and the results of the search will be stored in searchedTags state
  // this is so firebase is only called once when the component renders, and then the user searches the local state

  // call firebase when component first mounts, and stores tags into local state for use
  useEffect(() => {
    const getTags = async () => {
      // get data from firebase
      try {
        let result = await db.collection(pllanetq_tags).doc(pllanetq_tags).get()
        let doc = result.data()
        let setData = [];
        for (let tag in doc) {
          setData.push({ tag_name: doc[tag].tag_name, description: doc[tag].description, count: doc[tag].count });
        }

        // sort incoming data alphabetically
        setData.sort((a, b) => a.tag_name.localeCompare(b.tag_name))

        // set into local component state
        setTags(setData);
        setSearchedTags(setData);
        setLoadingTags(false)
      } catch (err) {
        // TODO: error catch here
        console.log(err)
      }

    }
    getTags();

  }, [])

  // when the input bar is changed, query the list for matching tags and display the result
  useEffect(() => {
    let result = tags.filter(tag => {
      // filter parameters for list of tags
      // if the tag is already selected, don't show up in the list
      if (currentTags.length > 0) {
        for (let i in currentTags) {
          if (tag.tag_name.toLowerCase().includes(currentTags[i].toLowerCase())) {
            return false;
          }
        }
      }
      // if it matches the search parameter and it isn't already a selected tag, it can appear on the list
      if (tag.tag_name.toLowerCase().includes(inputData.toLowerCase())) {
        return true
      }
      return false
    })
    setSearchedTags(result)

  }, [tags, inputData, currentTags])

  // Add event listener for clicking outside of tag list when component first mounts
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // set the input selected to false when clicking outside of the tag list
  const handleOutsideClick = e => {
    if (tagListWrapper.current && !tagListWrapper.current.contains(e.target)) {
      setInputSelected(false);
    }
  }
  // change and click handlers
  const onChange = e => setInputData(e.target.value);
  // call the onSelect function to pass the tag back to parent component
  const handleClick = (e, tag_name) => {
    onSelect(tag_name)
  }

  return (
    <TagListStyle>
      <Fragment>
        <input type="text" placeholder="eg: French, Preposition" value={inputData} onChange={e => onChange(e)} onClick={e => setInputSelected(true)} />
        { inputSelected && <div className="TagListSection" ref={tagListWrapper} >
          {/* render tag lists */}
          {inputSelected && (loadingTags ? <div>loading</div> :
            searchedTags.length > 0 ?
              searchedTags.map((tag) => <TagsListOption tag_name={tag.tag_name} description={tag.description} count={tag.count} handleClick={handleClick} key={tag.tag_name} />)
              : <p>No tag found</p>
          )}
        </div>}
      </Fragment>
    </TagListStyle>
  )
}

export default Tags
