import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import QuestionListItem from './QuestionListItem'
import { QuestionPage } from './common.styled';

const DisplayQuestionsByPage = ({ questions }) => {
  const [pageData, setPageData] = useState({
    offset: 0,
    perPage: 10,
    currentPage: 0,
  })
  const [slice, setSlice] = useState([])
  const handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * pageData.perPage;
    
    setPageData({ ...pageData,
      currentPage: selectedPage,
      offset: offset
    })
    setSlice(questions.slice(offset, offset + pageData.perPage))
  }
  
  useEffect(() => {
    setSlice(questions.slice(pageData.offset, pageData.offset + pageData.perPage))
    setPageData({
      ...pageData,
      pageCount: Math.ceil(questions.length / pageData.perPage)
    })

  }, [])
  return (
    <div>
      <QuestionPage>
        <div>
          {slice.map((question) => <QuestionListItem question={question} />)}
        </div>    

        {/* NOTE: styling for pagination is in App.css */}
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={null}
          breakClassName={'break-me'}
          pageCount={pageData.pageCount}
          marginPagesDisplayed={0}
          pageRangeDisplayed={5}
          onPageChange={e => handlePageClick(e)}

          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      </QuestionPage>
    </div>
  )
}

export default DisplayQuestionsByPage
