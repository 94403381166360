import React from 'react'
import { Link } from 'react-router-dom'

import { StyledTagComponent } from './common.styled';

const Tag = ({ tag_name, onSelect }) => {
  // show an x if onSelect is passed through, else don't show it
  return (
    <StyledTagComponent>
      <div className="TagSection">
        <Link to={`/tags?tag=${tag_name.toLowerCase()}`} >
          <button>{tag_name}</button>          
        </Link>
        {onSelect && <span onClick={e => onSelect(tag_name)}><button>X</button></span>}
      </div>
    </StyledTagComponent>
  )
}

export default Tag
