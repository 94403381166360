import styled from 'styled-components';

export const QuestionPageStyle = styled.div`
  .QuestionPage {
    padding: ${({ theme }) => theme.bigMargin} ${({ theme }) => theme.mediumMargin};
  } 
  .fullDiv {
    width: 100%;
  }
  .QuestionSection {
    display: flex;
    flex-direction: row;
  }
  .VotingSection {
    width: ${({ theme }) => theme.votingWidth};
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .Upvoted {
    color: ${({ theme }) => theme.amazon};
  }
  .Unupvoted {
    color: ${({ theme }) => theme.darkGrey};
  }
  .Upvoted, .Unupvoted {
    font-size: 30px;
    cursor: pointer;
    vertical-align: text-top;
  }
  .QuestionButtons {
    margin: ${({ theme }) => theme.mediumMargin} 0;
    button {
      margin-right: ${({ theme }) => theme.mediumMargin};
    }
  }
  .commentSection {
    width: 100%;
    height: 100px;
    resize: vertical;
    margin: ${({ theme }) => theme.smallMargin} 0;
  }
  .commentTitle {
    border-bottom: 1px solid ${({ theme }) => theme.darkGrey};
  }
  .addComment {
    width: 100%;
    height: 200px;
  }
  .postStuff {
    margin-bottom: ${({ theme }) => theme.smallMargin};
  }
  .answerCount {
    margin-top: ${({ theme }) => theme.bigMargin};
  }
`;

export const QuestionStyle = styled.div`
  .questionInfo {
    margin-bottom: ${({ theme }) => theme.mediumMargin};
  }
  .questionContent {
    margin: ${({ theme }) => theme.bigMargin} 0;
    border: 1px solid ${({ theme }) => theme.darkGrey};
    box-sizing: border-box;
    padding: ${({ theme }) => theme.mediumMargin} ${({ theme }) => theme.smallMargin};
    width: 100%;
  }
  .questionTags {
    display: flex;
  }
`;

export const CommentStyle = styled.div`
  .commentBlock {
    border-bottom: 1px solid ${({ theme }) => theme.darkGrey};
    display: flex;
    width: 100%;
  }
  .commentUpvoted {
    color: ${({ theme }) => theme.amazon};
  }
  .commentUnupvoted {
    color: ${({ theme }) => theme.darkGrey};
  }
  .commentUpvoted, .commentUnupvoted {
    font-size: 20px;
    cursor: pointer;
  }
  .commentVotingSection {
    width: 60px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .commentSignature {
    text-align: right;
  }
  .fullComment {
    width: 100%;
  }
`;

export const AnswerStyle = styled.div` 
  .answerSection {
    width: 100%;
    margin-top: ${({ theme }) => theme.mediumMargin};
    padding: ${({ theme }) => theme.smallMargin} 0;
    border-top: 1px solid ${({ theme }) => theme.darkGrey};
  }
`;