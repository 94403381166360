import React from 'react'
import { Link } from 'react-router-dom'

import { StyledTagComponent } from './common.styled';

const TagNoRedirect = ({ tag_name, onSelect }) => {
  // show an x if onSelect is passed through, else don't show it
  return (
    <StyledTagComponent>
      <div className="TagSection">
        <button>{tag_name}</button>
        {onSelect && <span onClick={e => onSelect(tag_name)}><button>X</button></span>}
      </div>
    </StyledTagComponent>
  )
}

export default TagNoRedirect
