import {
  GET_QUESTION_BY_ID, GET_QUESTIONS, CLEAR_CURRENT_QUESTION, CLEAR_QUESTIONS, GET_QUESTIONS_ALGOLIA
} from "../action/types"
const initialState = {
  currentQuestion: null,
  questions: [],
  algolia: null,
  loading: true
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_QUESTION_BY_ID:
      return {
        ...state,
        currentQuestion: payload,
        loading: false
      }
    case CLEAR_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: null,
        loading: true
      }
    case CLEAR_QUESTIONS:
      return {
        ...state,
        questions: null,
        algolia: null,
        loading: true
      }
    case GET_QUESTIONS:
      return {
        ...state,
        questions: payload,
        loading: false
      }
    case GET_QUESTIONS_ALGOLIA:
      return {
        ...state,
        questions: payload.result,
        algolia: payload.algolia,
        loading: false
      }
    
    default:
      return state
  }
}
