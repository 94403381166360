import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { db } from '../../firebase'
import { user_profile } from '../../values'
import Comment from './Comment'
import { commentAnswer, upvoteAnswer, downvoteAnswer } from '../../store/action/question'
import { pllanetq_questions, answers, upvotes, downvotes } from "../../values"
import { AnswerStyle, QuestionPageStyle, CommentStyle, QuestionStyle } from "./QuestionPage.styled"

const Answer = ({ answer, auth: { user, isAuthenticated }, question: { currentQuestion }, commentAnswer, upvoteAnswer, downvoteAnswer }) => {
  const { content, timestamp, author, answer_id } = answer
  const [displayed_name, setDisplayed_name] = useState("")
  const [inputData, setInputData] = useState({
    comment: ""
  })
  const [commentButtonClicked, setCommentButtonClicked] = useState(false)

  const [hasUserUpvoted, setHasUserUpvoted] = useState(false)
  const [hasUserDownvoted, setHasUserDownvoted] = useState(false)
  const [upvoteCount, setUpvoteCount] = useState(0)
  const [flag, setFlag] = useState(true)

  // get the author's name
  useEffect(() => {
    const getAuthorName = async () => {
      // let result = await $.get('/users/get_displayed_name', { user_id: author });
      // setDisplayed_name(result);
      const doc = await db.collection(user_profile).doc(author).get();
      setDisplayed_name(doc.data().displayed_name);
    }
    answer && getAuthorName()

  }, [answer])

  // check if user has upvoted or downvoted
  useEffect(() => {
    const checkVote = async () => {
      // if user's upvote is in the upvote collection, set the upvote state to true
      let search = await db.collection(pllanetq_questions).doc(currentQuestion.question_id).collection(answers).doc(answer_id).collection(upvotes).where('user_id', '==', user.uid).get();
      let upvote = search.docs.length > 0;
      setHasUserUpvoted(upvote)

      // if the user didn't upvote, check if the user downvoted
      if (!upvote) {
        // if user's upvote is in the upvote collection, set the upvote state to true
        let search = await db.collection(pllanetq_questions).doc(currentQuestion.question_id).collection(answers).doc(answer_id).collection(downvotes).where('user_id', '==', user.uid).get();
        setHasUserDownvoted(search.docs.length > 0)
      }
      // first time setup
      // get initial value of count, then calculate new values locally since there is a delay if we read from fb
      if (flag) {
        setUpvoteCount(answer.upvote_count);
        setFlag(false)
      }

    }
    currentQuestion && user && checkVote();


  }, [currentQuestion, user])



  const onChange = e => setInputData({ ...inputData, [e.target.name]: e.target.value });
  const submitComment = e => {
    commentAnswer(inputData.comment, currentQuestion.question_id, answer.answer_id)
    // reset values
    setCommentButtonClicked(false);
    setInputData({
      comment: ""
    })
  }
  // if user has already downvoted, delete the downvote then upvote
  const submitUpvote = e => {
    if (hasUserDownvoted) {
      submitDownvote(e)
      // double for deleting upvote then downvoting
      setUpvoteCount(hasUserUpvoted ? upvoteCount - 1 : upvoteCount + 2)
    }
    else {
      setUpvoteCount(hasUserUpvoted ? upvoteCount - 1 : upvoteCount + 1)
    }
    setHasUserUpvoted(!hasUserUpvoted)
    upvoteAnswer(currentQuestion.question_id, answer_id, hasUserUpvoted)
  }
  // if user has already upvoted, delete upvote then downvote
  const submitDownvote = e => {
    if (hasUserUpvoted) {
      submitUpvote(e)
      // double for deleting downvote then upvoting
      setUpvoteCount(hasUserDownvoted ? upvoteCount + 1 : upvoteCount - 2)
    }
    else setUpvoteCount(hasUserDownvoted ? upvoteCount + 1 : upvoteCount - 1)
    setHasUserDownvoted(!hasUserDownvoted)
    downvoteAnswer(currentQuestion.question_id, answer_id, hasUserDownvoted)
  }

  return (displayed_name !== "" &&
    <AnswerStyle>
      <QuestionPageStyle>
        <CommentStyle>
          <QuestionStyle>
            <div className="QuestionSection answerSection">
              <div className="VotingSection">
                <div className={hasUserUpvoted ? "Upvoted" : "Unupvoted"} onClick={e => submitUpvote(e)} >&#x25B2;</div>
                <div className="title">{upvoteCount}</div>
                <div className={hasUserDownvoted ? "Upvoted" : "Unupvoted"} onClick={e => submitDownvote(e)} >&#x25BC;</div>
              </div>
              <div className="fullDiv">
                <p className="questionInfo">{moment(timestamp).fromNow()} by <Link to={`/users/${author}`}>{displayed_name}</Link></p>
                <p className="questionContent paragraph">{content}</p>
                <div className="QuestionButtons">
                  {!commentButtonClicked && <button className="Button3 paragraphBig" title={!isAuthenticated ? "Log in or sign up to post a comment" : undefined} onClick={e => setCommentButtonClicked(true)} disabled={!isAuthenticated}>Comment</button>}
                  <a className="reportButton paragraph">Report</a>
                </div>
                {commentButtonClicked &&
                  <div>
                    <textarea className="commentSection paragraph" name="comment" placeholder="Use comments to ask for more information or suggest improvements. Avoid answering questions. Please be nice." value={inputData.comment} onChange={e => onChange(e)}></textarea>
                    <button className="Button4 paragraphBig postStuff" onClick={e => submitComment(e)}>Post Comment</button>
                  </div>
                }
                {/* <Comment /> */}
                <div className="commentSection">
                  <div className="title commentTitle">Comment</div>
                  {answer.comments.length > 0 ? answer.comments.map((comment) => <Comment comment={comment} question_id={currentQuestion.question_id} answer_id={answer_id} key={comment.comment_id} />) : <div className="paragraphBig">No comments yet. Why not be the first to comment?</div>}
                </div>
              </div>
            </div>
          </QuestionStyle>
        </CommentStyle>
      </QuestionPageStyle>
    </AnswerStyle>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  question: state.question
})

const mapDispatchToProps = {
  commentAnswer,
  upvoteAnswer,
  downvoteAnswer
}

export default connect(mapStateToProps, mapDispatchToProps)(Answer)

