import styled from 'styled-components';

export const PageLayout = styled.div`
position:relative;
display:flex;
flex-direction:column;
flex: 1;
`;

export const Row = styled.div`
display:flex;
flex-rection:row;
`;
