import React, { useState } from 'react'
import TagsList from '../common/TagsList'
import Tag from '../common/Tag'

const DashboardWatchTags = ({ watch_tags, handleSubmit, isUser }) => {
  const [inputData, setInputData] = useState(watch_tags ? { watch_tags } : { watch_tags: [] });
  const [message, setMessage] = useState("")
  const [toggleChange, setToggleChange] = useState(false);


  // when submitting, call function to change it in Dashboard.js
  const handleClick = e => {
    if (toggleChange) {
      handleSubmit(inputData);
    }
    setMessage("");
    setToggleChange(!toggleChange);
  }

  // on select, store into inputData
  const onSelect = (tag) => {
    
    // if it's in the array, remove it
    if (inputData.watch_tags.includes(tag)) {
      // remove from inputData, then return the rest of inputData
      let index = inputData.watch_tags.indexOf(tag)
      inputData.watch_tags.splice(index, 1)
      setInputData({ watch_tags: [...inputData.watch_tags] });
      setMessage("");
    }
    else {
      // check if there is more than 5 items
      if (inputData.watch_tags.length >= 5) {
        setMessage("Choose up to 5 tags");
      }
      else {
        // if it doesn't already exist in array and there is available space, then add it to the array
        setInputData({ watch_tags: [...inputData.watch_tags, tag.toLowerCase()] })
        setMessage("");
      }
    }
  }

  return (isUser ?
    <tr>
      <th>Watch Tags</th>
      <th>
        {/* display watch tags component which allows users to press x to delete, when clicked goes to tag page */}
        {inputData.watch_tags.length > 0 && (toggleChange? inputData.watch_tags.map((tag) => <Tag tag_name={tag} onSelect={onSelect} key={tag}/>) : watch_tags && watch_tags.map((tag) => <Tag tag_name={tag} key={tag}/>))}
        {/* display the tag search component when the change button is pressed */}
        {toggleChange && <TagsList onSelect={onSelect} currentTags={inputData.watch_tags}/>}
        {/* render message if it exists */}
        {message && <div>{message}</div>}
      </th>
      <th>
        <button name="watch_tags" onClick={e => handleClick(e)}>{toggleChange ? "Submit" : "Change"}</button>
      </th>
    </tr>
    : null
  )
}

export default DashboardWatchTags
