import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .linkStyleOverwrite{
    text-decoration: none;
    color: black;
    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
      color: black;
    } 
  }

  .titleBig {
    font-family: Roboto;
    font-style: normal;
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
  }
  
  .title {
    font-family: Roboto;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }

  .paragraphBig {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
  }

  .paragraph {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;    
  }

  .paragraphTight {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
  }

  .Button1{
    background: ${({ theme }) => theme.amazon};
    border: 0.3px solid #3E3E3E;
    box-sizing: border-box;
    border-radius: 2px;    
    text-align: center;
    padding: 6px 10px;
    color: black;
    display: inline-block;
    height: fit-content;
  }
  .Button1:hover{
    background: #DDAC33;
    text-decoration: none;
    color: black;
    height: 37px;
  }
  .Button1:active{
    background: #CF9400;
    height: 37px;
  }

  .Button2 {
    background: #FFFFFF;
    border: 0.3px solid rgba(0, 0, 0, 0.54);
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 10px;
    height: fit-content;
  }
  .Button2:hover {
    background: ${({ theme }) => theme.lightGrey};
    text-decoration: none;
  }
  .Button2:active {
    background: ${({ theme }) => theme.Grey};
  }
  
  .Button3{
    background: ${({ theme }) => theme.lightGrey};
    border: 0.3px solid rgba(0, 0, 0, 0.54);
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 10px;
    height: fit-content;
  }
  .Button3:hover{
    background: ${({ theme }) => theme.grey};
    text-decoration: none;
  }

  .Button4 {
    background: ${({ theme }) => theme.green};
    border-radius: 2px; 
    padding: 6px 10px;
    color: white;
    height: fit-content;
  }

`;
