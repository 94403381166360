import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../../store/action/auth';

import Toggle from '../ToggleButton/ToggleButton';
import SideMenu from '../SideMenu/SideMenu';
import { StyledNavBar } from './Navbar.styled';
import Logo from "../../assets/image/logo_white.png";

const Navbar = ({ auth: { loading, isAuthenticated, user }, logout }) => {

  const [open, setOpen] = useState(false);
  const menuId = "main-menu";

  // navbar content for guests
  const guestLinks = (
    <div className="rightItems">
      <Link className="linkStyleOverwrite paragraphBig signupButton" to="/register"><div className="Button2 paragraphBig">Sign up</div></Link>
      <Link className="linkStyleOverwrite" to="/login"><div className="Button2 paragraphBig">Log in</div></Link>
    </div>
  )
  // navbar content for logged in users
  // Profile link will send user to their own profile according to id, and id will be valid after user is loaded
  const userLinks = (
    <div className="rightItems">
      <Link className="linkStyleOverwrite paragraphBig profileButton" to={`/users/${user ? user.uid : ""}`}>Profile</Link>
      <Link className="linkStyleOverwrite" to="/" onClick={logout}><div className="Button2 paragraphBig">Log out</div></Link>
    </div>
  )


  // display different navbar based on if the user is logged in (isAuthenticated==true?)
  return (
    <StyledNavBar>
      <header>
        {/* <div><Toggle open={open} setOpen={setOpen}/>
        <SideMenu open={open} setOpen={setOpen} id={menuId}/>
    </div> */}
        <nav className="navigation">
        <a href="/"><img className="navbarLogo" src={Logo} alt="Logo"></img></a>
          <div className="Logo titleBig"><a href="/">PllanetQ</a></div>
          <ul>
            <div className="LeftItems">
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
              <li><Link to="/help">Help</Link></li>
              <li><a href="https://www.pllanet.ca">Pllanet</a></li>
            </div>
          </ul>


          <div className="MoveRight"> </div>
          <ul>
            {!loading && (isAuthenticated ? userLinks : guestLinks)}
          </ul>
        </nav>
      </header>
    </StyledNavBar>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
