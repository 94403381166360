import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { db } from '../../firebase';
import { user_profile } from '../../values';
import { upvoteComment, upvoteAnswerComment } from '../../store/action/question';
import { pllanetq_questions, answers, comments, upvotes } from "../../values";
import { CommentStyle } from './QuestionPage.styled';


const Comment = ({ auth: { user }, question: { currentQuestion }, comment, question_id, answer_id, upvoteAnswerComment, upvoteComment }) => {
  const { content, timestamp, author, comment_id } = comment
  const [displayed_name, setDisplayed_name] = useState("")
  const [hasUserUpvoted, setHasUserUpvoted] = useState(false)
  const [upvoteCount, setUpvoteCount] = useState(0)
  const [flag, setFlag] = useState(true)

  // get the author's name
  useEffect(() => {
    const getAuthorName = async () => {
      const doc = await db.collection(user_profile).doc(author).get();
      if (doc.exists && doc.data().displayed_name) {
        setDisplayed_name(doc.data().displayed_name);
      }
      else {
        setDisplayed_name("User Deleted");
      }
    }
    getAuthorName()

  }, [comment])

  // if user has already downvoted, delete the downvote then upvote
  const submitUpvote = e => {
    if (answer_id) {
      upvoteAnswerComment(question_id, answer_id, comment_id, hasUserUpvoted)
    }
    else {
      upvoteComment(question_id, comment_id, hasUserUpvoted)
    }
    setUpvoteCount(hasUserUpvoted ? upvoteCount - 1 : upvoteCount + 1)
    setHasUserUpvoted(!hasUserUpvoted)
  }

  // check if user has upvoted or downvoted
  useEffect(() => {
    const checkVote = async () => {
      // if user's upvote is in the upvote collection, set the upvote state to true
      let search = answer_id ?
        await db.collection(pllanetq_questions).doc(question_id).collection(answers).doc(answer_id).collection(comments).doc(comment_id).collection(upvotes).where('user_id', '==', user.uid).get() :
        await db.collection(pllanetq_questions).doc(question_id).collection(comments).doc(comment_id).collection(upvotes).where('user_id', '==', user.uid).get();
      let upvote = search.docs.length > 0;
      setHasUserUpvoted(upvote)

      // first time setup
      // get initial value of count, then calculate new values locally since there is a delay if we read from fb
      if (flag) {
        setUpvoteCount(comment.upvote_count);
        setFlag(false)
      }
    }
    currentQuestion && user && checkVote();


  }, [currentQuestion, user])


  return (displayed_name !== "" &&
    <CommentStyle>
      <div className="commentBlock">
        <div className="commentVotingSection">
          <div className={hasUserUpvoted ? "commentUpvoted" : "commentUnupvoted"} onClick={e => submitUpvote(e)} >&#x25B2;</div>
          <div className="title">{upvoteCount}</div>
        </div>
        <div className="fullComment">
          <div className="paragraph">{content}</div>
          <div className="paragraph commentSignature">{moment(timestamp).fromNow()} by <Link to={`/users/${author}`}>{displayed_name}</Link></div>
        </div>
      </div>
    </CommentStyle>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  question: state.question
})

const mapDispatchToProps = {
  upvoteComment,
  upvoteAnswerComment
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment)
