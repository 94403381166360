export const theme = {
    blue1: '#A5D5FF',
    blue2: '#74B5EF',
    amazon: '#F0C14B',
    stackoverflowBlue: '#0095FF',
    darkGrey: "#C4C4C4",
    grey: "#D9D9D9",
    lightGrey: "#F2F2F2",
    green: "#219653",
    textGrey: "#A7A4A4",

    primaryDark: '#0D0C1D',
    primaryLight: '#FFFFFF;',
    primaryHover: '#343078',
    mobile: '628px',
    ipad: '834px',

    tagPadding: '9px',
    smallMargin: '10px',
    mediumMargin: '25px',
    bigMargin: '50px',
    jumboMargin: '100px',
    votingWidth: '100px',
  }







