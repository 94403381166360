import React from 'react'
import { Link } from 'react-router-dom'

const TagsPageOption = ({ tag_name, description, count }) => {
  // renders an option in the tag page
  return (
    <Link to={`/?tag=${tag_name.toLowerCase()}`} style={{ textDecoration: 'none', color: "black" }}>
      <div name={tag_name} style={{ border: "1px solid green" }}>
        <div style={{ textTransform: "capitalize" }}>
          {tag_name}
        </div>
        <div>
          {count}
        </div>
        <div>
          {description}
        </div>
      </div>
    </Link>
  )
}

export default TagsPageOption
