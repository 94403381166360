import styled from 'styled-components';

export const StyledProfile = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 25px;
/* or 139% */
margin:auto;
display: flex;
align-items: center;
text-align: center;
margin:32px 50px;

.Tab{
    
}
.ButtonContainer button{
    width:140px;
    height:50px;
    border:none;
    
}

.ButtonContainer{
    display:flex;
    // 
    flex-flow: wrap;
    border-bottom: 1px solid #C4C4C4;
}

thead.DahBoardTop{
    padding-bottom:64px;
}
`;