import fb, { db } from "../../firebase"
import firebase from 'firebase'
import { pllanetq_questions, answers, comments, upvotes, downvotes, bookmarks } from "../../values"
import { setAlert, clearAlert } from "./alert"
import { GET_QUESTION_BY_ID, GET_QUESTIONS, CLEAR_CURRENT_QUESTION, CLEAR_QUESTIONS, GET_QUESTIONS_ALGOLIA } from "./types"
import $ from 'jquery'
import algoliaSearch from "../../algolia"

// creating question
export const createQuestion = (question) => async dispatch => {
  // send question to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)
    let res = await $.post('/questions/create_question', {
      token: idToken,
      question: {
        title: question.title,
        tags: question.tags,
        author: user.uid,
        content: question.content
      }
    })
    if (!res.id) {
      throw { message: res };
    }
    // get question by id and take the user to the question page
    dispatch(getQuestionById(res.id));
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }
}
// getting specific question by id
export const getQuestionById = (questionId) => async dispatch => {
  try {
    let doc = await db.collection(pllanetq_questions).doc(questionId).get()
    // convert timestamp to JS Date
    let payload = {
      ...doc.data(),
      timestamp: doc.data().timestamp.toDate().getTime()
    }

    // getting answers for questions
    let answersPayload = [];
    doc = await db.collection(pllanetq_questions).doc(questionId).collection(answers).get()
    doc.forEach(async (answer) => {
      let docData = answer.data()

      // get comments for the answer
      let answerCommentsPayload = [];
      let docComment = await db.collection(pllanetq_questions).doc(questionId).collection(answers).doc(docData.answer_id).collection(comments).get()
      docComment.forEach((comment) => {
        let dataComment = {
          ...comment.data(),
          timestamp: comment.data().timestamp.toDate().getTime()
        }
        answerCommentsPayload.push(dataComment)
      })

      let data = {
        ...docData,
        timestamp: docData.timestamp.toDate().getTime(),
        comments: answerCommentsPayload
      }
      answersPayload.push(data)
    })

    let commentsPayload = [];
    doc = await db.collection(pllanetq_questions).doc(questionId).collection(comments).get()
    doc.forEach((comment) => {
      let data = {
        ...comment.data(),
        timestamp: comment.data().timestamp.toDate().getTime()
      }
      commentsPayload.push(data)
    })


    payload = { ...payload, answers: answersPayload, comments: commentsPayload }
    dispatch({
      type: GET_QUESTION_BY_ID,
      payload
    })
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }
}

// for home page
export const getQuestionsAlgolia = (pageNumber, query, searchSetting, similarQuery = false) => async dispatch => {
  try {
    let searchParams = {
      ...searchSetting,
      page: pageNumber
    }
    // filters: 'answer_count>0 OR answer_count=0',
    // if similarQuery is selected, put it in search params for algolia
    if (similarQuery) searchParams = { ...searchParams, similarQuery: query }
    let search = await algoliaSearch.search(query, searchParams)
    let result = [];
    // put the appropriate data for displaying
    search.hits.forEach((hit) => {
      // let doc = db.collection(pllanetq_questions).doc(hit.objectID).get().data()
      let newHit = {
        ...hit,
        question_id: hit.objectID,
        tags: hit._tags
      };
      delete newHit.objectID;
      delete newHit._tags;
      result.push(newHit);

    })

    // Response from Algolia:
    // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
    dispatch({
      type: GET_QUESTIONS_ALGOLIA,
      payload: {
        result,
        algolia: {
          hitsPerPage: search.hitsPerPage,
          nbHits: search.nbHits,
          nbPages: search.nbPages,
          page: search.page
        }
      }
    })
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }
}
export const sortQuestions = (questions, sortBy) => async dispatch => {
  let sortedQuestions = []
  switch (sortBy) {
    case "newest":
      // sort current set of questions by newest
      sortedQuestions = questions.sort((a, b) => {
        return b.timestamp - a.timestamp
      })

      dispatch({
        type: GET_QUESTIONS,
        payload: sortedQuestions
      })

      break;
    case "mostUpvotes":
      // sort current set of questions by newest
      sortedQuestions = questions.sort((a, b) => {
        return b.upvote_count - a.upvote_count
      })
      dispatch({
        type: GET_QUESTIONS,
        payload: sortedQuestions
      })

      break;

    default:
      // default case, no need for change
      dispatch({
        type: GET_QUESTIONS,
        payload: questions
      })
  }
}
// for user profile
export const getUserQuestions = (user_id) => async dispatch => {
  try {
    let search = await db.collection(pllanetq_questions).where('author', '==', user_id).get();
    let result = [];
    search.forEach((question) => {
      result.push({
        ...question.data(),
        timestamp: question.data().timestamp.toDate().getTime()
      })
    })

    dispatch({
      type: GET_QUESTIONS,
      payload: result
    })
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }
}
export const getUserAnswers = (user_id) => async dispatch => {
  try {
    let search = await db.collectionGroup(answers).where('author', '==', user_id).get();
    let result = [];
    // the above query only gives us the answer document, not the question document so we need to find its parent and retrieve the parent document
    // instead of using forEach, we need to use a for look to read asynchronously to get all the questions
    for (const question of search.docs) {

      const getData = async (question) => {


        // getting parent document (question)
        let doc = await db.collection(pllanetq_questions).doc(question.ref.parent.parent.id).get()
        // returning the value of the parent document
        return {
          ...doc.data(),
          timestamp: doc.data().timestamp.toDate().getTime()
        }
      }

      // getting parent document (question)
      let data = await getData(question)
      // checks if the question already exists in results, if it doesn't then push the new question into results
      !result.some(existingQuestion => existingQuestion.question_id === data.question_id) && result.push(data)
    }

    dispatch({
      type: GET_QUESTIONS,
      payload: result
    })
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }
}
export const getUserBookmarks = () => async dispatch => {
  try {

    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)
    let search = await db.collectionGroup(bookmarks).where('user_id', '==', user.uid).get();
    let result = [];
    // the above query only gives us the bookmark document, not the question document so we need to find its parent and retrieve the parent document
    // instead of using forEach, we need to use a for look to read asynchronously to get all the questions
    for (const question of search.docs) {

      const getData = async (question) => {


        // getting parent document (question)
        let doc = await db.collection(pllanetq_questions).doc(question.ref.parent.parent.id).get()
        // returning the value of the parent document
        return {
          ...doc.data(),
          timestamp: doc.data().timestamp.toDate().getTime()
        }
      }

      // getting parent document (question)
      let data = await getData(question)
      // checks if the question already exists in results, if it doesn't then push the new question into results
      !result.some(existingQuestion => existingQuestion.question_id === data.question_id) && result.push(data)
    }

    dispatch({
      type: GET_QUESTIONS,
      payload: result
    })
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }
}

// for unmounting
export const clearCurrentQuestion = () => dispatch => {
  dispatch({ type: CLEAR_CURRENT_QUESTION })
}
export const clearQuestions = () => dispatch => {
  dispatch({ type: CLEAR_QUESTIONS })
}

// for questions page
export const answerQuestion = (content, question_id) => async dispatch => {
  // send question to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)
    let res = await $.post('/questions/answer_question', {
      token: idToken,
      answer: {
        author: user.uid,
        content,
        question_id
      }
    })
    if (!res.id) {
      throw { message: res };
    }
    // refresh question
    dispatch(getQuestionById(question_id));
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}
export const commentQuestion = (content, question_id) => async dispatch => {
  // send comment to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)
    let res = await $.post('/questions/comment_question', {
      token: idToken,
      comment: {
        author: user.uid,
        content,
        question_id
      }
    })
    if (!res.id) {
      throw { message: res };
    }
    // refresh question
    dispatch(getQuestionById(question_id));
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}
export const commentAnswer = (content, question_id, answer_id) => async dispatch => {
  // send comment to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)
    let res = await $.post('/questions/comment_answer', {
      token: idToken,
      comment: {
        author: user.uid,
        content,
        question_id,
        answer_id
      }
    })
    if (!res.id) {
      throw { message: res };
    }
    // refresh question
    dispatch(getQuestionById(question_id));
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}


export const upvoteQuestion = (question_id, hasUserUpvoted) => async dispatch => {
  // send vote to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)

    // if upvoted, remove document
    if (hasUserUpvoted) {
      await db.collection(pllanetq_questions).doc(question_id).collection(upvotes).doc(user.uid).delete()
    }
    // else, add upvote document to collection
    else {
      await db.collection(pllanetq_questions).doc(question_id).collection(upvotes).doc(user.uid).set({
        user_id: user.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })

    }
    // refresh question
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}
export const downvoteQuestion = (question_id, hasUserDownvoted) => async dispatch => {
  // send vote to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)

    // if downvoted, remove document
    if (hasUserDownvoted) {
      await db.collection(pllanetq_questions).doc(question_id).collection(downvotes).doc(user.uid).delete()
    }
    // else, add downvote document to collection
    else {
      await db.collection(pllanetq_questions).doc(question_id).collection(downvotes).doc(user.uid).set({
        user_id: user.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })

    }
    // refresh question
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}
export const bookmarkQuestion = (question_id, hasUserBookmarked) => async dispatch => {
  // send vote to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)

    // if bookmarked, remove document
    if (hasUserBookmarked) {
      await db.collection(pllanetq_questions).doc(question_id).collection(bookmarks).doc(user.uid).delete()
    }
    // else, add downvote document to collection
    else {
      await db.collection(pllanetq_questions).doc(question_id).collection(bookmarks).doc(user.uid).set({
        user_id: user.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })

    }
    // refresh question
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}

export const upvoteAnswer = (question_id, answer_id, hasUserUpvoted) => async dispatch => {
  // send vote to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)

    // if upvoted, remove document
    if (hasUserUpvoted) {
      await db.collection(pllanetq_questions).doc(question_id).collection(answers).doc(answer_id).collection(upvotes).doc(user.uid).delete()
    }
    // else, add upvote document to collection
    else {
      await db.collection(pllanetq_questions).doc(question_id).collection(answers).doc(answer_id).collection(upvotes).doc(user.uid).set({
        user_id: user.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })

    }
    // refresh question
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}
export const downvoteAnswer = (question_id, answer_id, hasUserDownvoted) => async dispatch => {
  // send vote to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)
    // if downvoted, remove document
    if (hasUserDownvoted) {
      await db.collection(pllanetq_questions).doc(question_id).collection(answers).doc(answer_id).collection(downvotes).doc(user.uid).delete()
    }
    // else, add downvote document to collection
    else {
      await db.collection(pllanetq_questions).doc(question_id).collection(answers).doc(answer_id).collection(downvotes).doc(user.uid).set({
        user_id: user.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })

    }
    // refresh question
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}

export const upvoteComment = (question_id, comment_id, hasUserUpvoted) => async dispatch => {
  // send vote to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)

    // if upvoted, remove document
    if (hasUserUpvoted) {
      await db.collection(pllanetq_questions).doc(question_id).collection(comments).doc(comment_id).collection(upvotes).doc(user.uid).delete()
    }
    // else, add upvote document to collection
    else {
      await db.collection(pllanetq_questions).doc(question_id).collection(comments).doc(comment_id).collection(upvotes).doc(user.uid).set({
        user_id: user.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })

    }
    // refresh question
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}
export const upvoteAnswerComment = (question_id, answer_id, comment_id, hasUserUpvoted) => async dispatch => {
  // send vote to firebase
  try {
    let user = fb.auth().currentUser;
    let idToken = await user.getIdToken(true)

    // if upvoted, remove document
    if (hasUserUpvoted) {
      await db.collection(pllanetq_questions).doc(question_id).collection(answers).doc(answer_id).collection(comments).doc(comment_id).collection(upvotes).doc(user.uid).delete()
    }
    // else, add upvote document to collection
    else {
      await db.collection(pllanetq_questions).doc(question_id).collection(answers).doc(answer_id).collection(comments).doc(comment_id).collection(upvotes).doc(user.uid).set({
        user_id: user.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })

    }
    // refresh question
    dispatch(clearAlert());
  } catch (err) {
    dispatch(setAlert(err.message, "error"));
  }

}