import {
  SET_ALERT, CLEAR_ALERT
} from "../action/types"

const initialState = {
  message: "",
  type: null
};

export default (state = initialState, { type, payload }) => {

  switch (type) {
    // User is logged in, set the user's information in the store
    case SET_ALERT:
      return {
        ...state,
        message: payload.message,
        type: payload.type
      }
    case CLEAR_ALERT:
      return {
        ...state,
        message: "",
        type: null
      }

    default:
      return state;
  }
}