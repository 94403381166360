import styled from 'styled-components';

export const StyledSideMenu = styled.nav`
  display: ${({ open }) => open ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: end;
  background: ${({ theme }) => theme.primaryLight};
  height: 100vh;
  text-align: left;
  padding-top: 6px;
  overflow:auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  top: 60px;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  
  // @media (max-width: ${({ theme }) => theme.mobile}) {
  //   width:100%;
  // }

  a {
    font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  padding: 40px 42px;
  text-decoration: none;
  cursor:pointer;
  justify-content:center;
    transition: color 0.3s linear;
      
     @media (max-width: ${({ theme }) => theme.mobile}) {
         
         text-align: center; 
     }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;