import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// If user is logged in (isAuthenticated == true), let them go to the link, otherwise redirect to login
const PrivateRoute = ({ component: Component, auth: { isAuthenticated, loading }, ...rest }) => (
  <Route {...rest} render={props => !isAuthenticated && !loading ? (<Redirect to='/login' />) : (<Component {...props} />)} />
)

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
