import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../../store/action/auth';


import { StyledNavBar } from './Footer.styled';
import Logo from "../../assets/image/logo_white.png";

const Footer = () => {


  // display different navbar based on if the user is logged in (isAuthenticated==true?)
  return (
    <StyledNavBar>
      <footer>
        <div className="FirstLine">
          <div className="Address">
            <h1>Pllanet</h1>
            <p>151 Charles St W Suite# 199</p>
            <p> Kitchener, ON N2G 1H6 </p>
          </div>
          <nav className="navigation">
            <ul>
              <div className="LeftItems">
                <li><Link to="/about">About</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/help">Help</Link></li>
                <li><a href="http://www.pllanet.ca">Pllanet</a></li>
              </div>
            </ul>
          </nav>
          <a className="logo_section" href="/"><img className="navbarLogo" src={Logo} alt="Logo"></img></a>
        </div>
        <div className="LastLine">
          © 2020 Pllanet - made with <div className="heart"> ♥ </div> for the people of the internet
        </div>
      </footer>
    </StyledNavBar>
  )
}

// const mapStateToProps = (state) => ({
//   auth: state.auth,
// })

// const mapDispatchToProps = {
//   logout
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Footer)
export default Footer;