import React from 'react';
import { SearchBarStyle } from './common.styled';

const SearchBar = ({ name, value, placeholder, onChange, onSubmit }) => {

  return (
    <div>
      <SearchBarStyle>
        <i className="icon-search"></i>
        <input type="text" name={name} onChange={e => onChange(e)} onKeyPress={(e) => { (e.key === 'Enter' && onSubmit(e)) }} placeholder={placeholder} value={value} />
      </SearchBarStyle>      
    </div>
  )
}

export default SearchBar
