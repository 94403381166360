import React, { useState } from 'react';
import { languages } from '../../values';

const DashboardLearningLanguage = ({ learning_language, handleSubmit, isUser }) => {
  // component states for storing input and toggling input render
  const [inputData, setInputData] = useState(learning_language ? { learning_language } : { learning_language: '' })
  const [toggleChange, setToggleChange] = useState(false)

  // store user input in component state
  const onChange = e => setInputData({ ...inputData, [e.target.name]: e.target.value });

  // when submitting, call function to change it in Dashboard.js
  const handleClick = e => {
    if (toggleChange) {
      handleSubmit(inputData);
    }
    setToggleChange(!toggleChange);
  }
  return (isUser ?
    <tr>
      <th>Learning Language</th>
      <th>
        {toggleChange ?
          // mapping through language list to create dropdown
          <select name="learning_language" value={inputData.learning_language} onChange={e => onChange(e)}>
            <option value="" disabled>Choose a location</option>
            {languages.map((lang, index) => <option value={lang} key={index}>{lang}</option>)}
          </select>
          : inputData.learning_language}
      </th>
      <th>
        <button name="learning_language" onClick={e => handleClick(e)}>{toggleChange ? "Submit" : "Change"}</button>
      </th>
    </tr> :
    <tr>
      <th>Learning Language</th>
      <th>{learning_language}</th>
    </tr>

  )
}

export default DashboardLearningLanguage
