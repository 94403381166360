import React, { useState } from 'react';
import { interests } from '../../values';

const DashboardPersonalInterests = ({ user_interest, handleSubmit, isUser }) => {
  // component states for storing input and toggling input render
  const [inputData, setInputData] = useState(user_interest? { user_interest: [...user_interest] } : {user_interest: []})
  const [toggleChange, setToggleChange] = useState(false)
  const [message, setMessage] = useState(null)


  // toggle the value in user_interest array on check
  const onCheck = (e, interest) => {
    // if it's in the array, remove it
    if (inputData.user_interest.includes(interest)) {
      // remove from inputData, then return the rest of inputData
      let index = inputData.user_interest.indexOf(interest)
      inputData.user_interest.splice(index, 1)
      setInputData({ user_interest: [...inputData.user_interest] });
      setMessage(null)
    }
    else {
      // check if there is more than 3 items
      if (inputData.user_interest.length >= 3) {
        
        setMessage("Choose up to 3 items")
      }
      else {
        // if it doesn't already exist in array and there is available space, then add it to the array
        setInputData({ user_interest: [...inputData.user_interest, interest] })
        setMessage(null)
      }
    }
  }

  // let checkbox be checked if it's already in the array
  const isChecked = interest => {
    return inputData.user_interest.includes(interest) ? true : false
  }

  // when submitting, call function to change it in Dashboard.js
  const handleClick = e => {
    if (toggleChange) {
      handleSubmit(inputData);
    }
    setToggleChange(!toggleChange);
  }

  // HTML for checkbox, maps through all the available interests and renders them in Checkbox.js
  return (isUser ?
    <tr>
      <th>Personal Interests</th>
      <th>
        {message && <div>{message}</div>}
        {toggleChange ?
          // if user pressed change, map through all the available interests and display them as a checkbox
          interests.map((interest, index) =>
            <label key={index}>
              <input type="checkbox" name={"user_interest"} value={interest} onChange={e => onCheck(e, interest)} checked={isChecked(interest)} />
              {interest}
            </label>
          )
          // if user didn't press, simply render the user_interest array
          : inputData.user_interest.join(", ")}
      </th>
      <th>
        <button name="user_interest" onClick={e => handleClick(e)}>{toggleChange ? "Submit" : user_interest? "Change" : "Add"}</button>
      </th>
    </tr> :
    <tr>
      <th>Personal Interests</th>
      <th>{user_interest && user_interest.join(", ")}</th>
    </tr>
  )
}
export default DashboardPersonalInterests
