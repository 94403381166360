import {
  GET_PROFILE, CLEAR_PROFILE, UPDATE_PROFILE, GET_USERS, PROFILE_ERROR, CLEAR_USERS, SORT_USERS
} from "../action/types"
const initialState = {
  profile: null,
  users: [],
  loading: true
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case GET_PROFILE:
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: payload,
        loading: false
      }
    case GET_USERS:
      return {
        ...state,
        users: [...state.users, ...payload],
        loading: false
      }
    case SORT_USERS:
      return {
        ...state,
        users: payload,
        loading: false
      }
    case CLEAR_USERS:
      return {
        ...state,
        users: [],
        loading: false
      }
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
        loading: true

      }
    case PROFILE_ERROR:
      return {
        ...state,
        profile: null,
        loading: false

      }
    default:
      return state
  }
}
