import React from 'react'
import { connect } from 'react-redux'

// can show "success" and "error" messages, they are they types in the store and can be used as class names to style accordingly
const alert = ({alert: {message, type}}) => {
  return ( message ?
    <div className={type} style={{alignSelf:'center',padding:"20px" }}>
      {message}
    </div>
    : null
  )
}

const mapStateToProps = (state) => ({
  alert: state.alert
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(alert)

