import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { register } from '../../store/action/auth';
import { setAlert, clearAlert } from '../../store/action/alert';
import { languages, hear_about_us } from '../../values';
import { StyledLogIn } from './Login.styled';

import Logo from "../../assets/image/logo_black.png";

const Register = ({ isAuthenticated, register, setAlert, clearAlert }) => {
  useEffect(() => {
    return () => {
      clearAlert();
    }
  }, [clearAlert])
  // create state to collect form information
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    displayed_name: '',
    learning_language: '',
    native_language: '',
    hear_about_us: '',
    hear_about_us_other: ''
  })

  // onChange and onSubmit functions to update state and to execute dispatcher
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    e.preventDefault();
    // check if the user confirmed the password correctly
    if (formData.password === formData.confirmPassword) {
      // submit form data to register action function
      let submitHearAboutUs = formData.hear_about_us === "Other" ? formData.hear_about_us_other : formData.hear_about_us;
      submitHearAboutUs = submitHearAboutUs.toLowerCase()
      register(formData.email, formData.password, formData.displayed_name, formData.learning_language, formData.native_language, submitHearAboutUs);
    }
    else {
      // display a message that says passwords don't match
      setAlert("Passwords don't match", "error");
    }

  }

  // redirect to home if logged in
  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  // HTML render
  return (
    <StyledLogIn>
      <div>
        <img src={Logo} />
        <h1 className="titleBig">Please proceed to Pllanet to sign up for an account to use PllanetQ</h1>
        <h4 className="title">A Pllanet account enables you to access both PllanetQ and Pllanet</h4>
        <div>
          <a className="title" href="https://www.pllanet.ca/ll_signup">Sign up as a Language Learner</a>
        </div>
        <div class="bottomText">
          <a className="title" href="https://www.pllanet.ca/ns_signup">Sign up as a Native Speaker</a>
        </div>       
      </div>

      {/* Actual sign up codes */}
      {/* <div>
          <h3>Register</h3>
          <label>Email: </label>
          <input type="email" placeholder="Email Address" name="email" value={formData.email} onChange={e => onChange(e)} required /><br />
          <label>Password: </label>
          <input type="password" placeholder="Password" name="password" minLength="6" value={formData.password} onChange={e => onChange(e)} required /><br />
          <label>Confirm Password: </label>
          <input type="password" placeholder="Confirm Password" name="confirmPassword" minLength="6" value={formData.confirmPassword} onChange={e => onChange(e)} required /><br />
          <label>Display Name: </label>
          <input type="text" placeholder="Display Name" name="displayed_name" value={formData.displayed_name} onChange={e => onChange(e)} required /><br />
          <label>Learning Language: </label>
          <select name="learning_language" value={formData.learning_language} onChange={e => onChange(e)} required>
            <option value="" disabled>Learning language</option>
            {languages.map((lang, index) => <option value={lang} key={index}>{lang}</option>)}
          </select>
          <label>Native Language: </label>
          <select name="native_language" value={formData.native_language} onChange={e => onChange(e)} required>
            <option value="" disabled>Native language</option>
            {languages.map((lang, index) => <option value={lang} key={index}>{lang}</option>)}
          </select>
          <label>How did you hear about us?</label>
          <select name="hear_about_us" value={formData.hear_about_us} onChange={e => onChange(e)} required>
            <option value="" disabled>How did you hear about us?</option>
            {hear_about_us.map((source, index) => <option value={source} key={index}>{source}</option>)}
          </select>
          {formData.hear_about_us === "Other" && <input name="hear_about_us_other" value={formData.hear_about_us_other} onChange={e => onChange(e)} />}
          <button onClick={e => onSubmit(e)}>Register</button>
        </div> */}
    </StyledLogIn>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = {
  register,
  setAlert,
  clearAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)


