import React, { useEffect, useState } from 'react'
import Tag from './Tag';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment'
import $ from 'jquery'
import { db } from '../../firebase'
import { user_profile } from '../../values';

import { StyledQuestionComponent } from './common.styled';

const QuestionListItem = ({ question }) => {
  const { question_id, title, content, timestamp, author, tags, upvote_count, answer_count } = question;
  const [displayed_name, setDisplayed_name] = useState("")

  // get the author's name
  useEffect(() => {
    const getAuthorName = async () => {
      const doc = await db.collection(user_profile).doc(author).get();
      if (doc.exists) {
        setDisplayed_name(doc.data().displayed_name);
      }
      else {
        setDisplayed_name("User Deleted");
      }
    }
    getAuthorName()

  }, [question])

  // the whole block is wrapped with a Link tag, when the block is clicked it will go to the question
  return (displayed_name !== "" &&
    <StyledQuestionComponent>
      <div className="QuestionComponent">
        <div className="Stats">
          <div className="StatComponent title">
            <div className="StatNumber StatNumberMobile title" >{upvote_count}</div>
            <div className="StatTitle paragraphBig">Upvotes</div>            
          </div>
          <div className="StatComponent title">
            <div className={answer_count>0 ? "StatNumberGreen StatNumber StatNumberMobile title" : "StatNumber StatNumberMobile title"}>{answer_count}</div>
            <div className={answer_count>0 ? "StatTitle StatNumberGreen paragraphBig" : "StatTitle paragraphBig"}>Answers</div>    
          </div>
        </div>
        <div className="Sections">
          <div className="QuestionSections">
            <Link to={`/questions/${question_id}`} >
              <h2 className="title">{title}</h2>
            </Link>
            <p className="QuestionContent paragraphTight">{content}</p>
          </div>
          <div className="OtherSections">
            <div className="QuestionTags">
              {tags && tags.map((tag) => <Tag tag_name={tag} />)}
            </div>
            <div className="QuestionAuthor paragraphTight">
              {moment(timestamp).fromNow()} by <Link to={`/users/${author}`}>{displayed_name}</Link>
            </div>
          </div>
        </div>
      </div>
    </StyledQuestionComponent>
  )
}

export default QuestionListItem
