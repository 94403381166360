import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getProfileById, clearProfile } from '../../store/action/profile'

import Dashboard from './Dashboard'
// import Questions from './Questions'
// import { clearAlert } from '../../store/action/alert';

import { StyledProfile } from './Profile.styled';

import { clearAlert } from '../../store/action/alert'
import ProfileQuestions from './ProfileQuestions'
import ProfileAnswers from './ProfileAnswers'
import ProfileBookmarks from './ProfileBookmarks'
import ProfileTags from './ProfileTags'

// match prop allows us to access the parameter in the url
const Profile = ({ auth, profile: { profile, loading }, getProfileById, clearProfile, clearAlert, match }) => {
  const user = auth.user;
  // checks if the logged in user is equal to the profile, if it is, allow them to edit and see bookmarks
  const isUser = !auth.loading && user && user.uid === match.params.user_id;

  // component state for toggling different sections of the profile, using custom hook
  const [profileComponents, toggleProfileComponents] = useToggleSingleComponents({
    Dashboard: true,
    Questions: false,
    Answers: false,
    Tags: false,
    Bookmarks: false
  })

  // load the profile of user id in url, and toggle dashboard first
  useEffect(() => {
    getProfileById(match.params.user_id);
    // cleanup
    return () => {
      clearProfile();
      clearAlert();
    }
  }, [getProfileById, match.params.user_id, clearProfile, clearAlert])

  // toggle which section of the profile to render based on state
  const renderComponents = () => {
    if (profileComponents.Dashboard) return <Dashboard isUser={isUser} user_id={match.params.user_id} />
    else if (profileComponents.Questions) return <ProfileQuestions user_id={match.params.user_id}/>
    else if (profileComponents.Answers) return <ProfileAnswers user_id={match.params.user_id}/>
    else if (profileComponents.Tags) return <ProfileTags pllanetq_tags={profile.read_only.pllanetq_tags} />
    else if (profileComponents.Bookmarks && isUser) return <ProfileBookmarks />
  }

  // click handler
  const handleClick = (e) => {
    toggleProfileComponents(e.target.name);
  }


  // loading to let the app retrieve profile info
  return (loading && profile === null ?
    <div>loading...</div> :
    profile === null || !isUser ?
        <div>No profile found</div> :
        <StyledProfile>
          <div className="Tab">
            <div className="ButtonContainer">
                <button name="Dashboard" onClick={e => handleClick(e)}>Dashboard</button>
                <button name="Questions" onClick={e => handleClick(e)}>Questions</button>
                <button name="Answers" onClick={e => handleClick(e)}>Answers</button>
                <button name="Tags" onClick={e => handleClick(e)}>Tags</button>
                <button name="Bookmarks" onClick={e=> handleClick(e)}>Bookmarks</button>
              </div>
                {/* {isUser && <button name="Bookmarks" onClick={e => handleClick(e)}>Bookmarks</button>} */}
                {renderComponents()} 
          </div>
        </StyledProfile>
  )
}

// custom hook to toggle components
const useToggleSingleComponents = (initialState) => {
  const [state, setState] = useState(initialState);
  // toggle component function, set everything to false, and the component that needs to be rendered to true
  const toggle = (component) => {
    let newState = { ...state };
    // set all values in newState to false, but if it is the matching component make it true
    for (let i in newState) {
      if (i === component) {
        newState[i] = true;
      }
      else{
        newState[i] = false;
      }
    }
    // set it into component's state
    setState({...newState});
  }
  return [state, toggle];
}

// // custom hook to toggle multiple components
// const useToggleMultipleComponents = (initialState) => {
//   const [state, setState] = useState(initialState);
//   // toggle component function, set everything to false, and the component that needs to be rendered to true
//   const toggle = (component) => {
//     let newState = { ...state };
//     // flip the value of the matching parameter
//     for (let i in newState) {
//       if (i === component) {
//         newState[i] = !newState[i];
//       }
//     }
//     // set it into component's state
//     setState({...newState});
//   }
//   return [state, toggle];
// }

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile
})

const mapDispatchToProps = {
  getProfileById,
  clearProfile,
  clearAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
