import React from 'react';
import { StyledContact } from './Page.styled';
import Logo from "../../assets/image/logo_black.png";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'


const About = () => {
  return (
      <StyledContact>
        <div>
           <img src={Logo}/>
           <Form>
           <h1>Got questions for us? Let us know below</h1>
                <Form.Group controlId="formBasicEmail">
                    <Form.Control type="Name" placeholder="name" />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control as="textarea" rows="5" />
                </Form.Group>
                <Button type="submit">
                    Submit
                </Button>
            </Form>
        </div>
      </StyledContact>
  )
}


export default About;
