import styled from 'styled-components';

export const StyledNavBar = styled.footer`
    position:relative;
    width:100%;
    background: #3E3E3E;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    color: #F2F2F2;
    .heart{
        color:red;
        width: 16px;
        height: 16px;
        display: inline-block;
    }

    .FirstLine{
        display: grid;
        grid-template-areas: "Address Link Logo";
    }

    .Address{
        grid-area: Address;
        padding-left: ${({ theme }) => theme.mediumMargin};
        padding-top: ${({ theme }) => theme.mediumMargin};
    }
    .navbarLogo {
        width: 110px;
        height: 80px;
        margin-top: ${({ theme }) => theme.mediumMargin};
    }
    p{
        margin:0;
    }
    .footer{
        padding:inherit;
    }
    .navigation {
        display: flex;
        align-items: center;
        padding: 1rem;
        grid-area: Link;
        margin: 0 auto;
    }
    .Logo{
        grid-area: Logo;
        align-self: center;
    }
    .logo_section {
        margin-right: ${({ theme }) => theme.smallMargin};
        margin-left: auto;
    }
    }

    .Logo a {
        text-decoration:none;
        font-size:1.5rem;
        padding-right: 28px;
        cursor:pointer;
        color: #F2F2F2;
    }

     ul {
        list-style:none;
        margin: 0;
        padding: 0;
        display: flex;
    }

    .LeftItems{
        display: flex;
        grid-area: Link;
    }

    li{
        display:flex;
        justify-content:center;
    }

     li a {
        padding-right: 28px;
        text-decoration:none;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        cursor:pointer;
        }

 
    .LastLine{
        text-align: center;
        padding:40px;
    }

     @media (max-width: ${({ theme }) => theme.mobile}) {
         .logo_section {
             margin: 0 auto;
         }
         .FirstLine {
            display: grid;
            grid-template-areas: 
            "Address Logo"
            "Link Link";
         }
         .navigation {
            margin: ${({ theme }) => theme.mediumMargin} auto;
         }
         .LastLine {
             padding: ${({ theme }) => theme.smallMargin}
         }
  }
`;