import React from 'react';
import { StyledHelp } from './Page.styled';



const About = () => {
  return (
      <StyledHelp>
          <div>
            <h1>Help Center</h1>
            <p>Welcome to PllanetQ’s Help Center, find out more about:</p>
            <br></br>
            <h5>Rules and guidelines</h5>
            <h5>Terms and Conditions</h5>
            <h5>How do I ask a question?</h5>
            <h5>How do I answer a question?</h5>
            <h5>Comment vs answer</h5>
            <h5>How tags work?</h5>
            <h5>Do I have to ask, answer and comment in English?</h5>
            <h5>Can I ask a question related to homework or exams?</h5>
            <h5>How does the point system work?</h5>
            <h5>Which info in my profile is public?</h5>
            <h5>Are Pllanet and PllanetQ accounts the same?</h5>
            <h5>How to report?</h5>
            <h5>How to get on the Leaderboard?</h5>
            <h5>Ads and Partnership</h5>
        </div>
      </StyledHelp>
  )
}


export default About;
