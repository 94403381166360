import React, { useState } from 'react';
import { Countries } from '../../values';

const DashboardNativeLocation = ({ native_location, handleSubmit, isUser }) => {
  // component states for storing input and toggling input render
  const [inputData, setInputData] = useState(native_location ? { native_location } : { native_location: '' })
  const [toggleChange, setToggleChange] = useState(false)

  // store user input in component state
  const onChange = e => setInputData({ ...inputData, [e.target.name]: e.target.value });

  // when submitting, call function to change it in Dashboard.js
  const handleClick = e => {
    if (toggleChange) {
      handleSubmit(inputData);
    }
    setToggleChange(!toggleChange);
  }
  // if this field doesn't exist, let the user add it
  if (!native_location && isUser) {
    return (
      <tr>
        <th>From</th>
        <th>
          {toggleChange ?
            // mapping through location list to create dropdown
            <select name="native_location" value={inputData.native_location} onChange={e => onChange(e)}>
              <option value="" disabled>Choose a location</option>
              {Countries.map((country, index) => <option value={country} key={index}>{country}</option>)}
            </select>
            : inputData.native_location}
        </th>
        <th>
          <button name="native_location" onClick={e => handleClick(e)}>{toggleChange ? "Submit" : "Add"}</button>
        </th>
      </tr>
    )
  }
  // if it does exist, the user can't edit it anymore
  return (
    <tr>
      <th>From</th>
      <th>{native_location}</th>
    </tr>
  )
}

export default DashboardNativeLocation
