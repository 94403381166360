import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { db } from '../../firebase';
import { pllanetq_tags } from '../../values'
import TagsPageOption from './TagsPageOption'

const TagsPage = ({location}) => {

  const [inputData, setInputData] = useState("")
  const [searchedTags, setSearchedTags] = useState([])
  const [tags, setTags] = useState([])
  const [loadingTags, setLoadingTags] = useState(true)
  const [sortBy, setSortBy] = useState("tag_name")
  const [desc, setDesc] = useState(true)

  // call firebase when component first mounts, and stores tags into local state for use
  useEffect(() => {
    const getTags = async () => {
      // get data from firebase
      try {
        let result = await db.collection(pllanetq_tags).doc(pllanetq_tags).get()
        let doc = result.data()
        let setData = [];
        for (let tag in doc) {
          setData.push({ tag_name: doc[tag].tag_name, description: doc[tag].description, count: doc[tag].count });
        }
        // sort incoming data alphabetically
        setData.sort((a, b) => a.tag_name.localeCompare(b.tag_name))

        // set into local component state
        setTags(setData);
        setSearchedTags(setData);
        setLoadingTags(false)
      } catch (err) {
        // TODO: error catch here
      }

    }
    getTags();
    // check if tag query exists. if it does, then enter it as the search
    const params = new URLSearchParams(location.search);
    const tag = params.get('tag'); 
    tag && setInputData(tag)
  }, [])

  // when the input bar is changed, query the list for matching tags and display the result
  // when the arrows are clicked, sort them by the corresponding property and order
  useEffect(() => {
    let sortedTags = []
    switch (sortBy) {
      case "count":
        sortedTags = desc? tags.sort((a, b) => { return b.count - a.count }) : tags.sort((a, b) => { return a.count - b.count })
        break;
      default:
        sortedTags = desc? tags.sort((a, b) => a.tag_name.localeCompare(b.tag_name)) : tags.sort((a, b) => b.tag_name.localeCompare(a.tag_name))
        break;
    }

    // filter parameters for list of tags
    // if it matches the search parameter and it isn't already a selected tag, it can appear on the list
    let result = sortedTags.filter(tag => tag.tag_name.toLowerCase().includes(inputData.toLowerCase()))
    setSearchedTags(result)

  }, [tags, inputData, sortBy, desc])

  // change and click handlers
  const onChange = e => setInputData(e.target.value);
  const sortSearchedTags = (e) => {
    let sort = e.target.getAttribute("name");
    // if user hits same sort again, change the order from descending to ascending, vice versa
    if (sortBy === sort) {
      setDesc(!desc);
    }
    // the user hit a different sort, do descending by default
    else{
      setDesc(true)
    }
    setSortBy(sort);
  }

  return (
    <div style={{ margin: "100px 200px" }}>
      <div>
        <h1>Tags</h1>
        <p>A tag is a keyword or label that categorizes your question with other similar questions. Using the right tags makes it easier for others to find and answer your question.</p>
      </div>
      {/* search */}
      <div>
        <div>Tag Name <span name="tag_name" onClick={e => sortSearchedTags(e)}>^</span></div>
        <div># of Questions <span name="count" onClick={e => sortSearchedTags(e)}>^</span></div>
      </div>
      <div>
        <input type="text" name="search" onChange={e => onChange(e)} placeholder="Search by name" value={inputData} />
      </div>
      {/* results */}
      {(loadingTags ? <div>loading</div> :
        searchedTags.length > 0 ?
          searchedTags.map((tag) => <TagsPageOption tag_name={tag.tag_name} description={tag.description} count={tag.count} key={tag.tag_name} />)
          : <p>No tag found</p>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TagsPage)
