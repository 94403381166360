// Contains the styling for all the different Pages

import styled from 'styled-components';


// About Page

export const StyledAbout = styled.div`
margin:auto;
font-family: Roboto;
font-style: normal;
font-weight: 500;
display: flex;
align-items: center;
text-align: center;
color: #000000;
padding:0 75px;

h1{
    font-size: 28px;
    line-height: 50px;
}

h3{
    font-size: 18px;
    line-height: 30px;
}

img{
    align-self:center;
    width:80px;
    margin:20px;
}

img.aboutPic{
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    width: 432px;
    height: 307px;
}

.Screenshots {
  margin-bottom: ${({ theme }) => theme.bigMargin};
}

@media (max-width: ${({ theme }) => theme.mobile}) {
  padding:0 ${({ theme }) => theme.smallMargin};
  img.aboutPic{
    margin: ${({ theme }) => theme.smallMargin} 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
}
`;

// Contact

export const StyledContact = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 28px;
line-height: 30px;
/* identical to box height, or 107% */
display: flex;
align-items: center;
text-align: center;
margin:auto;
margin-bottom: 140px;
color: #000000;

form{
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    padding:30px;
}

h1{
    font-size: 28px;
    line-height: 50px;
    padding-bottom:55px;
}

h3{
    font-size: 18px;
    line-height: 30px;
}

img{
    align-self:center;
    width:80px;
    margin:20px;
}

button{
    margin-bottom:30px;
    margin-top:60px;
    background: #F2F2F2;
    /* Button Border */
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    border: 0.3px solid rgba(0, 0, 0, 0.54);
    box-sizing: border-box;
    border-radius: 2px;
}

input{
    background: #F2F2F2;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
}

`;

// Help Page

export const StyledHelp = styled.div`
margin-left:55px;
margin-bottom: 140px;

h1{
    margin-top:55px;
    font-size: 28px;
    line-height: 50px;
}

h5{
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    padding:8px;
    display: flex;
    align-items: center;

    color: #0095FF;

}

img{
    align-self:center;
    width:80px;
    margin:20px;
}

`;

export const StyledHome = styled.div`
  width: 100%;
    .mainTitle {
        margin: ${({ theme }) => theme.bigMargin} ${({ theme }) => theme.mediumMargin};
    }
    .HeadSection {
        display: flex;
        margin: 0 ${({ theme }) => theme.mediumMargin}; 
        margin-bottom: ${({ theme }) => theme.bigMargin};
        justify-content: space-between;
        white-space: nowrap;
    }
    .searchSection{
        width:60%;
        margin-right: ${({ theme }) => theme.jumboMargin};
    }
    .homeButtons button {
      margin-right: ${({ theme }) => theme.mediumMargin}; 
    }
    .customFilter {
      background-color:  ${({ theme }) => theme.grey};
      padding: ${({ theme }) => theme.mediumMargin};
      margin: 0 ${({ theme }) => theme.mediumMargin};
      margin-bottom: ${({ theme }) => theme.mediumMargin};
      .filters{
        display: flex;
        flex-direction: row;
        .filter_row {
          display: flex;
          flex-direction: column;
          label {
            margin-left: ${({ theme }) => theme.smallMargin};
          }
        }
      }
      .customs{
        display: flex; 
        margin-top: ${({ theme }) => theme.smallMargin};
        .custom_title {
          margin-right: ${({ theme }) => theme.smallMargin};
          display: flex;
          align-items: center;
        }
        .ApplyFilter {
          margin-left: ${({ theme }) => theme.mediumMargin};          
        }
        .SelectedTags {
          display: flex;
          flex-direction: column;
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.ipad}) {
      .HeadSection {
        display: flex;
        flex-direction: column;
      }
      .searchSection{
        width:100%;
        margin-right: 0;
      }
      .homeButtons{
        align-self:center;
        margin-top: ${({ theme }) => theme.mediumMargin};
      }
      .homeButtons button {
        margin-right: ${({ theme }) => theme.bigMargin}; 
      }
    }
`;