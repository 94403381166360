import styled from 'styled-components';

export const StyledQuestionComponent = styled.div`
border: 1px solid #C4C4C4;
box-sizing: border-box;

.QuestionComponent{
  display:flex;
  padding: ${({ theme }) => theme.smallMargin};
}
.QuestionComponent:hover{
  background-color: ${({ theme }) => theme.lightGrey};
}

 h2{
  color:black;
  text-decoration: none;
 }
.Stats{
  display:flex;
  justify-content:center;  
}
.StatComponent{
  width: 65px;
  text-align: center;
  margin-right: ${({ theme }) => theme.smallMargin};
  align-self: center;
}
.StatNumber{
  margin-bottom: ${({ theme }) => theme.smallMargin};
}
.StatNumberGreen{
  color: ${({ theme }) => theme.green};  
}
.Sections{
  display:flex;
  flex-direction: column;
  width: -webkit-fill-available;
  width: 100%;
}
.QuestionSections{
  display:flex;
  flex-direction: column;
}
.OtherSections{
  display:flex;
}
.QuestionContent{
  overflow-wrap:anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.QuestionTags{
  display:flex;
  width: 60%;  
}
.QuestionAuthor{
  width: 40%;
  text-align: right;
  color: ${({ theme }) => theme.textGrey};
}

@media (max-width: ${({ theme }) => theme.mobile}) {
  .QuestionComponent{
    flex-direction: column;
    padding-top: 0;
  }
  .OtherSections{
    flex-direction: column;
  }
  .QuestionTags{
    width: 100%;
  }
  .QuestionAuthor{
    width: 100%;
  }
  .StatComponent{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    background-color: ${({ theme }) => theme.grey};
    margin: 0;
  }
  .StatNumberMobile{
    text-align: center;
    margin-right: ${({ theme }) => theme.mediumMargin};
    padding: 0;
  }
  .StatNumber{
    margin-bottom: 0;
  }
  .QuestionComponent{
    padding: 0;
  }
  .Sections {
    padding: ${({ theme }) => theme.smallMargin};
  }
  .StatTitle {
    margin: auto 0;
  }
}
`;

export const StyledTagComponent = styled.div`
  .TagSection {
    display: flex;
    padding-right: ${({ theme }) => theme.tagPadding};
  }
  button {
    background-color: ${({ theme }) => theme.blue1};    
    font-size: 14px;
    text-align: center;
    border-radius: 2px;
    border: none;
    padding: 0px 5px;
  }
  button:hover{
    background-color: ${({ theme }) => theme.blue2};
  }
  
`

export const QuestionPage = styled.div`
  margin: 0 ${({ theme }) => theme.mediumMargin};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 0;
  }
`
export const SearchBarStyle = styled.div`
  input{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    height: 34px;
    padding: 0 ${({ theme }) => theme.smallMargin};
  }
`
export const TagListStyle = styled.div`
  input {
    height: 34px;
    background: #FFFFFF;
    border: 1px solid ${({ theme }) => theme.darkGrey};
    box-sizing: border-box;
    border-radius: 2px;
  }
  .TagListSection {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-row-gap: ${({ theme }) => theme.smallMargin};
    grid-column-gap: ${({ theme }) => theme.bigMargin};
    height: 230px;
    overflow: auto;
    border: 1px solid ${({ theme }) => theme.darkGrey};
    box-sizing: border-box;
    position: absolute;
    width: 70%;
    background: white;
    padding: ${({ theme }) => theme.smallMargin};
    border-radius: 2px;
  }
  .TagListTitle {
    display:flex;
    justify-content: space-between;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    .TagListSection {
      display: flex;
      flex-direction: column;
    }
  }
`