import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import UsersListItem from './UsersListItem';
import { getUsersByQuery, sortUsers, clearUsers } from '../../store/action/profile'
import { clearAlert } from '../../store/action/alert';
import SearchBar from '../common/SearchBar';

const Users = ({ profile: { users, loading }, getUsersByQuery, clearUsers, sortUsers, clearAlert }) => {
  const [query, setQuery] = useState("");
  const [bottom, setBottom] = useState(false)
  const [limit, setLimit] = useState(1)
  const [offset, setOffset] = useState(0)

  const [sortBy, setSortBy] = useState("displayed_name")
  const [desc, setDesc] = useState(true)

  useEffect(() => {
    getUsersByQuery(offset, limit, query);
    return () => clearAlert();

  }, [getUsersByQuery, offset, clearAlert])

  // add listener for scrolling to implement lazy scroll
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  // event handlers
  const handleScroll = (e) => {
    // check if scrolled to bottom
    if (window.innerHeight + Math.round(document.documentElement.scrollTop) === document.documentElement.offsetHeight) {
      setOffset(prevState => prevState + limit);
    }
    // setBottom(window.innerHeight + Math.round(document.documentElement.scrollTop) === document.documentElement.offsetHeight)
  }
  const onChange = e => setQuery(e.target.value);
  const onSubmit = e => {
    clearUsers()
    setOffset(0)
  }

  useEffect(() => {
    users.length > 0 && sortUsers(sortBy, desc, users)
    
  }, [sortBy, desc])

  const sortSearchedUsers = (e) => {
    let sort = e.target.getAttribute("name");
    // if user hits same sort again, change the order from descending to ascending, vice versa
    if (sortBy === sort) {
      setDesc(!desc);
    }
    // the user hit a different sort, do descending by default
    else{
      setDesc(true)
    }
    setSortBy(sort);
  }

  return (loading && users ? <div style={{ margin: "100px 200px" }}>loading...</div> :
    <div style={{ margin: "100px 200px" }}>
      <div>
        <h1>Users</h1>
      </div>
      <SearchBar name="search" value={query} placeholder="Search" onChange={onChange} onSubmit={onSubmit} />
      {/* headers */}
      <div>
        <div>Name <span name="displayed_name" onClick={e => sortSearchedUsers(e)}>^</span></div>
        <div>Points <span name="points" onClick={e => sortSearchedUsers(e)}>^</span></div>
        <div>Questions <span name="question_count" onClick={e => sortSearchedUsers(e)}>^</span></div>
        <div>Answers <span name="answer_count" onClick={e => sortSearchedUsers(e)}>^</span></div>
      </div>
      {/* users */}
      {users ?
        users.map((user) => <UsersListItem key={user.user_id} user={user} />)
        :
        "No users found"
      }

    </div>
  )
}

const mapStateToProps = (state) => ({
  profile: state.profile
})

const mapDispatchToProps = {
  getUsersByQuery,
  clearAlert,
  sortUsers,
  clearUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
