import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledNavBar = styled.header`
    position:relative;
    width:100%;
    background: #3E3E3E;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    .navigation {
        display: flex;
        height:100%;
        align-items: center;
        padding: ${({ theme }) => theme.smallMargin};
    }
    .navbarLogo {
        display: flex;
        align-items: center;
        width: 53px;
        height: 40px;
        display: none;
    }
    .Logo a {
        color: white;
        text-decoration:none;
        padding-right: 28px;
        cursor:pointer;
    }

     ul {
        list-style:none;
        margin: 0;
        padding: 0;
        display: flex;
    }

    .LeftItems{
        display: flex;
    }

     li a {
        padding-right: 28px;
        color:white;
        text-decoration:none;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        cursor:pointer;
        }

    .MoveRight {
        flex:1;
    }

    div.button a{
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        background: #FFFFFF;
        border: 0.3px solid rgba(0, 0, 0, 0.54);
        box-sizing: border-box;
        border-radius: 2px;
        padding: 5px 15px;
    }

    .rightItems{
        display:flex;
        flex:1;
        .profileButton {
            color: white;
            margin-right: ${({ theme }) => theme.mediumMargin};
            display: flex;
            align-items: center; 
        }
        .signupButton {
            margin-right: ${({ theme }) => theme.mediumMargin};
        }
    }

     @media (max-width: ${({ theme }) => theme.mobile}) {
         .Logo{
            display:none;
         }

         .LeftItems li{
             display:none;
         }
         .navbarLogo {            
            margin-left: ${({ theme }) => theme.bigMargin};
            display: block;
        }
  }
`;