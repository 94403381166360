import React, { useState, Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import QuestionForm from './QuestionForm'
import ReviewQuestion from './ReviewQuestion'
import {clearCurrentQuestion} from '../../store/action/question'

const CreateQuestion = ({auth: {loading, user}, clearCurrentQuestion, history}) => {
  useEffect(() => {
    clearCurrentQuestion()
  }, [])

  const [question, setQuestion] = useState({
    title: "",
    content: "",
    tags: []
  })
  const [editing, setEditing] = useState(true)

  return (
    <Fragment>
      {!loading && (editing? <QuestionForm question={question} setQuestion={setQuestion} setEditing={setEditing} user_id={user.uid}/> : <ReviewQuestion createdQuestion={question} setEditing={setEditing} history={history}/>)}
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

const mapDispatchToProps = {
  clearCurrentQuestion
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestion)
