import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getUserQuestions, clearQuestions } from '../../store/action/question'
import DisplayQuestionsByPage from '../common/DisplayQuestionsByPage'

const ProfileQuestions = ({ user_id, question: { questions, loading }, getUserQuestions, clearQuestions }) => {
  useEffect(() => {
    getUserQuestions(user_id)
    return () => {
      clearQuestions()
    }
  }, [])
  return (loading ? <div>loading...</div> :
    <div>
      <div>
        <h1>Questions</h1>
        <div>{questions.length}</div>
      </div>
      {/* <div>
        {questions.map((question) => <QuestionListItem question={question} />)}
      </div> */}
      <DisplayQuestionsByPage questions={questions} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  question: state.question
})

const mapDispatchToProps = {
  getUserQuestions,
  clearQuestions
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileQuestions)
