import styled from 'styled-components';

export const StyledsideBar = styled.nav`
display: flex;
flex-direction: column;
background: ${({ theme }) => theme.primaryLight};
text-align: left;
padding-top: 6px;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
border-radius: 10px;
width: 136.5px;
z-index: 1;

ul{
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}


li{
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.textGrey};
  padding: 40px 42px;
  text-decoration: none;
  cursor:pointer;
  justify-content:center;
}
a {
  color: #000000;
  text-decoration: none;
  cursor:pointer;
}
  
.Enabled {
  color: #000000;
}

.Disabled {
  cursor: default;
}
.NotLoggedMessage {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}
.Disabled:hover .NotLoggedMessage{
  visibility: visible;
}

@media (max-width: ${({ theme }) => theme.mobile}){
  display:none;
}


`;