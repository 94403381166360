import React,{useState} from 'react';
import { bool, func } from 'prop-types';
import { StyledToggleButton } from './ToggleButton.styled';

import SideMenu from '../SideMenu/SideMenu';

const Toggle = ({ open, setOpen }) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  return (
    <StyledToggleButton open={open} onClick={() => {
      setOpen(!open)
      setShowSideMenu(!showSideMenu);
    }
    }>
      <div />
      <div />
      <div />
       {/* {showSideMenu && <SideMenu display="none !important"/>} */}
    </StyledToggleButton>
  )
}

Toggle.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Toggle;