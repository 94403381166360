import React, {useEffect,useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import PrivateRoute from './components/common/PrivateRoute';
import Alert from './components/common/Alert';

import Home from './components/main/Home';
import About from './components/main/About';
import Contact from './components/main/Contact';
import Help from './components/main/Help';

import Navbar from './components/NavBar/Navbar';
import Profile from './components/profile/Profile';
import CreateQuestion from './components/CreateQuestion/CreateQuestion';
import Sidebar from './components/SideBar/Sidebar';
import Footer from './components/Footer/Footer';
import {PageLayout,Row} from './app.styled';

import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { GlobalStyle } from './globalStyles';
import {loadUser} from './store/action/auth'
import { Provider } from 'react-redux';
import store from './store/store';



import Toggle from  './components/ToggleButton/ToggleButton';
 import SideMenu from './components/SideMenu/SideMenu';

import './App.css';
import Users from './components/users/Users';
import QuestionsPage from './components/QuestionsPage/QuestionsPage';
import TagsPage from './components/TagsPage/TagsPage';
// import Sidebar from './components/main/Sidebar';


const App = () => {
  const [open, setOpen] = useState(false);
  
const menuId = "main-menu";
  // check if there is a user logged in each time this page loads
  useEffect(() => {
    store.dispatch(loadUser());
  }, [])
  return (

    <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <PageLayout>
        <GlobalStyle />
            <Navbar/>
            <Row>
              <Sidebar /> 
              
              <div className="Mobile"><Toggle open={open} setOpen={setOpen}/>
              <SideMenu open={open} setOpen={setOpen} id={menuId}/>
              </div>
              <Route exact path='/' component={Home} />
              <Route exact path='/about' component={About} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/help' component={Help} />

              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/users" component={Users} />

                <Route exact path="/users/:user_id" component={Profile} />
                <Route exact path="/tags" component={TagsPage} />


                {/* Questions */}
          <PrivateRoute exact path="/questions/ask" component={CreateQuestion} />
          <Route exact path="/questions/:question_id" component={QuestionsPage} />
              </Switch>
            </Row>
            <Alert /> 
            <Footer /> 
          {/* </div> */}
          
        </PageLayout>
      </Router>
    </Provider>
    </ThemeProvider>
  );
}

export default App;
