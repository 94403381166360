import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../store/action/auth';
import { clearAlert } from '../../store/action/alert';
import { Link } from 'react-router-dom'

import { StyledLogIn } from './Login.styled';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import Logo from "../../assets/image/logo_black.png";

const Login = ({ isAuthenticated, login, clearAlert }) => {
  useEffect(() => {
    return () => {
      clearAlert()
    }
  }, [clearAlert])
  // create state to collect form information
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const { email, password } = formData

  // onChange and onSubmit functions to update state and to execute dispatcher
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  }

  // redirect to home if logged in
  if (isAuthenticated) {
    return <Redirect to="/" />
  }
  // HTML render
  return (
    <StyledLogIn>
      <div>
        <img src={Logo} />
        <h1 className="titleBig">Pllanet Account Login Portal</h1>
        <h4 className="title">A Pllanet account enables you to access both PllanetQ and Pllanet
        <br></br>Do not have an account? Try <Link to="/register">Sign up</Link></h4>
        <div>
          <input type="email" placeholder="Email Address" name="email" value={email} onChange={e => onChange(e)} required /><br />
          <input type="password" placeholder="Password" name="password" minLength="6" value={password} onChange={e => onChange(e)} required /><br />
          {/* Todo: have forgot email firebase thing */}
          <h5 className="paragraphBig">Forgot your password?</h5>
          <button className="Button3 paragraphBig" type="submit" onClick={e => onSubmit(e)}>Login</button>
        </div>
      </div>
      {/* <StyledLogIn>
      <div>
        <img src={Logo} />
        <Form>
            <h1><strong>Pllanet Account Login Portal</strong></h1>
            <h5>A Pllanet account enables you to access both PllanetQ and Pllanet</h5>
            <h5>Do not have an account? Try Sign up </h5>
        <Form.Group controlId="formBasicEmail">
          <Form.Control type="email" placeholder="Email" name="email" value={email} onChange={e => onChange(e)} required />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Control type="password" placeholder="Password" name="password" minLength="6" value={password} onChange={e => onChange(e)} required />
        </Form.Group>
        <Form.Text><h5>Forgot your password?</h5></Form.Text>
        <Button className="Button3" type="submit" onClick={e => onSubmit(e)}></Button>
      </Form>
      </div> */}
    </StyledLogIn>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = {
  login,
  clearAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)


